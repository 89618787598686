import React, { createContext, useContext, useState, useEffect } from 'react';
import { Product } from '../types';
import { toast } from 'react-toastify'; // Import toastify

export interface CartItem {
  product: Product;
  quantity: number;
}

interface CartContextType {
  items: CartItem[];
  addItem: (product: Product, quantity: number) => void;
  removeItem: (productId: string) => void;
  updateQuantity: (productId: string, quantity: number) => void;
  clearCart: () => void;
  totalItems: number;
  showAddAnimation: boolean;
  getTotal: () => number;
}

const CartContext = createContext<CartContextType>({
  items: [],
  addItem: () => {},
  removeItem: () => {},
  updateQuantity: () => {},
  clearCart: () => {},
  totalItems: 0,
  showAddAnimation: false,
  getTotal: () => 0
});

export const useCart = () => useContext(CartContext);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [items, setItems] = useState<CartItem[]>(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [showAddAnimation, setShowAddAnimation] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  // Update localStorage and total items whenever cart changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(items));
    const total = items.reduce((sum, item) => sum + item.quantity, 0);
    setTotalItems(total);
  }, [items]);

  const addItem = (product: Product, quantity: number) => {
    setItems((currentItems) => {
      const existingItem = currentItems.find((item) => item.product.id === product.id);
      if (existingItem) {
        return currentItems.map((item) =>
          item.product.id === product.id
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      }
      return [...currentItems, { product, quantity }];
    });

    setShowAddAnimation(true);
    setTimeout(() => {
      setShowAddAnimation(false);
    }, 1000);

    toast.success('Produto adicionado ao carrinho');
  };

  const removeItem = (productId: string) => {
    setItems((currentItems) =>
      currentItems.filter((item) => item.product.id !== productId)
    );
  };

  const updateQuantity = (productId: string, quantity: number) => {
    if (quantity <= 0) {
      removeItem(productId);
      return;
    }

    setItems((currentItems) =>
      currentItems.map((item) =>
        item.product.id === productId ? { ...item, quantity } : item
      )
    );
  };

  const clearCart = () => {
    setItems([]);
    localStorage.removeItem('cart');
  };

  const getTotal = () => {
    return items.reduce((total, item) => {
      const price = Number(item.product.salePrice || item.product.price) || 0;
      return total + (price * item.quantity);
    }, 0);
  };

  const value = {
    items,
    addItem,
    removeItem,
    updateQuantity,
    clearCart,
    getTotal,
    totalItems,
    showAddAnimation
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default CartContext;