import React, { useEffect } from 'react';
import LoginForm from '../components/auth/LoginForm';
import { useAuth } from '../contexts/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !loading) {
      navigate('/client-area', { replace: true });
    }
  }, [user, loading, navigate]);

  // Não redireciona enquanto está carregando
  if (loading) {
    return <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
    </div>;
  }

  // Se já estiver logado, não renderiza nada (o useEffect cuidará do redirecionamento)
  if (user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="https://plataformaconcreta.pt/logos/emtuacasa/logo_pretoemtuacasa.png"
          alt="EM TUA CASA®"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-secondary">
          Iniciar Sessão
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Ou{' '}
          <a href="/register" className="font-medium text-primary hover:text-primary-dark">
            crie uma nova conta
          </a>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <LoginForm onClose={() => {}} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;