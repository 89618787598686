import React from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import ProductCard from './ProductCard';
import { Loader } from 'lucide-react';
import { useEffect, useState } from 'react';

interface Product {
  id: string;
  name: string;
  description: string;
  imageUrl?: string;
  images?: string[];
  regularPrice: number;
  salePrice?: number;
  category: string;
  sponsored: boolean;
  sponsorshipStartDate?: string;
  sponsorshipEndDate?: string;
  stock: number;
  stockStatus: string;
}

interface CategoryProducts {
  category: string;
  products: Product[];
}

const SponsoredProducts = () => {
  const [sponsoredByCategory, setSponsoredByCategory] = useState<CategoryProducts[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSponsoredProducts = async () => {
      try {
        setLoading(true);
        const currentDate = new Date();
        const productsRef = collection(db, 'products');
        const productsSnapshot = await getDocs(productsRef);
        
        const sponsoredProducts = productsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() } as Product))
          .filter(product => 
            product.sponsored &&
            product.sponsorshipStartDate &&
            product.sponsorshipEndDate &&
            new Date(product.sponsorshipStartDate) <= currentDate &&
            new Date(product.sponsorshipEndDate) >= currentDate &&
            product.stock > 0 &&
            product.stockStatus === 'instock'
          );

        // Agrupar por categoria
        const productsByCategory = sponsoredProducts.reduce((acc, product) => {
          if (!product.category) return acc;
          
          const existingCategory = acc.find(cat => cat.category === product.category);
          if (existingCategory) {
            existingCategory.products.push(product);
          } else {
            acc.push({
              category: product.category,
              products: [product]
            });
          }
          return acc;
        }, [] as CategoryProducts[]);

        // Ordenar produtos em cada categoria por preço
        productsByCategory.forEach(category => {
          category.products.sort((a, b) => {
            const priceA = a.salePrice || a.regularPrice;
            const priceB = b.salePrice || b.regularPrice;
            return priceA - priceB;
          });
        });

        // Filtrar categorias que têm produtos
        const categoriesWithProducts = productsByCategory.filter(
          category => category.products.length > 0
        );

        setSponsoredByCategory(categoriesWithProducts);
      } catch (error) {
        console.error('Erro ao buscar produtos patrocinados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSponsoredProducts();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader className="w-8 h-8 animate-spin text-secondary" />
      </div>
    );
  }

  if (sponsoredByCategory.length === 0) {
    return null;
  }

  return (
    <div className="space-y-12">
      {sponsoredByCategory.map(({ category, products }) => (
        <div key={category} className="my-8">
          <h2 className="text-2xl font-bold text-secondary mb-6">
            Em Destaque em {category}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {products.slice(0, 4).map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SponsoredProducts;
