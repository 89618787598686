import React from 'react';
import { useCart } from '../contexts/CartContext';
import BaseLayout from './BaseLayout';

interface CartAwareLayoutProps {
  children: React.ReactNode;
  noLayout?: boolean;
}

const CartAwareLayout: React.FC<CartAwareLayoutProps> = ({ children, noLayout }) => {
  const { showAddAnimation } = useCart();
  
  return (
    <>
      {showAddAnimation && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-4 py-2 rounded-md shadow-lg z-50 animate-fade-out">
          Produto adicionado ao carrinho!
        </div>
      )}
      <BaseLayout noLayout={noLayout}>
        {children}
      </BaseLayout>
    </>
  );
};

export default CartAwareLayout;
