import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Search, ShoppingCart, Heart, User, LogOut, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';
import CategoriesMenu from './CategoriesMenu';

const Navbar: React.FC = () => {
  const { user, signOut, userData } = useAuth();
  const { items, getTotal } = useCart();
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const cartTotal = getTotal();
  const hasCartItems = items.length > 0;

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      window.location.href = `/search?q=${encodeURIComponent(searchQuery.trim())}`;
      setShowMobileSearch(false);
    }
  };

  return (
    <nav className="bg-[#1B3B68] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Mobile Menu Button */}
          <div className="flex items-center lg:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-white hover:text-gray-300 focus:outline-none"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>

          {/* Desktop Categories Button */}
          <div className="hidden lg:flex items-center space-x-4">
            <button
              onClick={() => setIsMenuOpen(true)}
              className="p-2 rounded-lg hover:bg-[#2c4f82] transition-colors"
              aria-label="Menu de categorias"
            >
              <Menu className="w-6 h-6" />
            </button>
          </div>
            
          <Link to="/" className="flex-shrink-0">
            <img 
              src="https://plataformaconcreta.pt/logos/emtuacasa/logo_branco_emtuacasa.png" 
              alt="EM TUA CASA® - marketplace de confiança" 
              className="h-8 w-auto"
            />
          </Link>

          {/* Search Bar - Desktop */}
          <div className="hidden lg:flex flex-1 max-w-3xl mx-8">
            <form onSubmit={handleSearch} className="relative w-full">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Pesquisar produtos..."
                className="w-full px-4 py-2 rounded-md bg-[#144272] text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <button 
                type="submit"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 hover:text-white focus:outline-none"
              >
                <Search className="h-5 w-5 text-gray-300" />
              </button>
            </form>
          </div>

          {/* Mobile Search Toggle */}
          <div className="lg:hidden">
            <button
              onClick={() => setShowMobileSearch(!showMobileSearch)}
              className="text-white hover:text-gray-300 focus:outline-none"
            >
              <Search className="h-6 w-6" />
            </button>
          </div>

          {/* Right Navigation - Desktop */}
          <div className="hidden lg:flex items-center space-x-6">
            {user ? (
              <div className="flex items-center space-x-4 text-white">
                <span className="hidden xl:inline">Olá, {userData?.name || user.email?.split('@')[0] || 'Utilizador'}</span>
                <button
                  onClick={signOut}
                  className="hover:text-gray-300 focus:outline-none"
                  title="Terminar Sessão"
                >
                  <LogOut className="h-5 w-5" />
                </button>
              </div>
            ) : (
              <div className="text-white space-x-2">
                <Link to="/login" className="hover:text-gray-300">Login</Link>
                <span className="text-gray-400">|</span>
                <Link to="/register" className="hover:text-gray-300">Registo</Link>
              </div>
            )}

            <Link to={user ? '/client-area' : '/login'} className="text-white hover:text-gray-300">
              <User className="h-6 w-6" />
            </Link>
            <Link to="/favorites" className="text-white hover:text-gray-300">
              <Heart className="h-6 w-6" />
            </Link>
            <Link to="/cart" className="text-white hover:text-gray-300 relative">
              <ShoppingCart className="h-6 w-6" />
              {hasCartItems && (
                <div className="absolute -top-3 -right-3 bg-primary text-white text-xs px-2 py-1 rounded-full">
                  €{cartTotal.toFixed(2)}
                </div>
              )}
            </Link>
          </div>
        </div>

        {/* Mobile Search Bar */}
        {showMobileSearch && (
          <div className="lg:hidden py-4">
            <form onSubmit={handleSearch} className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Pesquisar produtos..."
                className="w-full px-4 py-2 rounded-md bg-[#144272] text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <button 
                type="submit"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 hover:text-white focus:outline-none"
              >
                <Search className="h-5 w-5 text-gray-300" />
              </button>
            </form>
          </div>
        )}

        {/* Categories Menu */}
        <CategoriesMenu 
          isOpen={isMenuOpen} 
          onClose={() => setIsMenuOpen(false)} 
        />
      </div>
    </nav>
  );
};

export default Navbar;