import React from 'react';
import Navbar from '../components/Navbar';
import SubNavbar from '../components/SubNavbar';
import Footer from '../components/Footer';
import MobileNavigation from '../components/MobileNavigation';
import DemoMessage from '../components/DemoMessage';

interface BaseLayoutProps {
  children: React.ReactNode;
  noLayout?: boolean;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ children, noLayout }) => {
  if (noLayout) {
    return <>{children}</>;
  }

  return (
    <div className="min-h-screen flex flex-col">
      <DemoMessage />
      <Navbar />
      <SubNavbar />
      <main className="flex-1 pb-16 lg:pb-0">
        {children}
      </main>
      <Footer />
      <MobileNavigation />
    </div>
  );
};

export default BaseLayout;
