import React from 'react';
import HeroSection from '../components/HeroSection';
import FeaturedCategories from '../components/FeaturedCategories';
import PromotionalProducts from '../components/PromotionalProducts';
import RecentProducts from '../components/RecentProducts';
import RecentSellers from '../components/RecentSellers';
import SponsoredProducts from '../components/SponsoredProducts';
import SEO from '../components/SEO';

const HomePage = () => {
  return (
    <>
      <SEO 
        title="EM TUA CASA® - marketplace de confiança"
        description="Descubra o EM TUA CASA®, o seu marketplace de confiança. Encontre os melhores produtos com preços competitivos e entrega para todo Portugal."
      />
      
      <div className="container mx-auto px-4">
        <HeroSection />
        <FeaturedCategories />
        <SponsoredProducts />
        <PromotionalProducts />
        <RecentProducts />
        <RecentSellers />
      </div>
    </>
  );
};

export default HomePage;