import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from '../types';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut as firebaseSignOut, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  userRole: string | null;
  userData: any | null;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  userRole: null,
  userData: null,
  signIn: async () => {},
  signOut: async () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [userData, setUserData] = useState<any | null>(null);

  useEffect(() => {
    console.log('Setting up auth listener');
    let mounted = true;
    
    // Set persistence to LOCAL
    setPersistence(auth, browserLocalPersistence).then(() => {
      const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
        console.log('Auth state changed:', { email: firebaseUser?.email });
        
        try {
          if (firebaseUser && mounted) {
            const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
            const userDocData = userDoc.exists() ? userDoc.data() : null;
            
            // Converter FirebaseUser para nosso tipo User
            const user: User = {
              ...firebaseUser,
              role: userDocData?.role?.toLowerCase() || 'customer', // Valor padrão
              displayName: firebaseUser.displayName,
              email: firebaseUser.email,
              photoURL: firebaseUser.photoURL
            };
            setUser(user);
            setUserRole(userDocData?.role?.toLowerCase() || null);
            setUserData(userDocData);
          } else if (mounted) {
            setUser(null);
            setUserRole(null);
            setUserData(null);
          }
        } catch (error) {
          console.error('Error in auth state change:', error);
          if (mounted) {
            setUser(null);
            setUserRole(null);
            setUserData(null);
          }
        } finally {
          if (mounted) {
            setLoading(false);
          }
        }
      });

      return () => {
        mounted = false;
        unsubscribe();
      };
    });
  }, []);

  const handleSignIn = async (email: string, password: string) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      setLoading(true);
      await firebaseSignOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    loading,
    userRole,
    userData,
    signIn: handleSignIn,
    signOut: handleSignOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;