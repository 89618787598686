import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useOrder } from '../hooks/useOrder';
import { useMultibancoPayment } from '../hooks/useMultibancoPayment';
import { CheckCircle, Loader, Landmark, MapPin } from 'lucide-react';
import MBWayCountdown from '../components/MBWayCountdown';

const OrderConfirmationPage = () => {
  const { id } = useParams();
  const { order, loading, error } = useOrder(id);
  const { payment, loading: paymentLoading } = useMultibancoPayment(id);

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return 'Data não disponível';
      }
      return date.toLocaleDateString('pt-PT');
    } catch (error) {
      return 'Data não disponível';
    }
  };

  if (loading || paymentLoading) {
    return (
      <div className="flex justify-center items-center h-96">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error || !order) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center text-red-500">
          {error || 'Encomenda não encontrada'}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center">
        <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
        <h1 className="text-3xl font-bold text-secondary mb-2">
          Encomenda Confirmada!
        </h1>
        <p className="text-gray-600 mb-8">
          Obrigado pela sua compra. O seu número de encomenda é:
        </p>
        <div className="bg-gray-50 p-4 rounded-lg mb-8">
          <p className="text-2xl font-bold text-secondary">
            #{order.orderNumber}
          </p>
          <p className="text-sm text-gray-500 mt-2">
            Guarde este número para consultar o estado da sua encomenda
          </p>
        </div>

        {/* Shipping Address */}
        <div className="bg-white shadow-md rounded-lg p-6 mb-8">
          <div className="flex items-center justify-center space-x-2 mb-4">
            <MapPin className="w-6 h-6 text-primary" />
            <h2 className="text-xl font-semibold text-secondary">Morada de Entrega</h2>
          </div>
          <div className="text-left bg-gray-50 p-4 rounded-lg">
            <p className="font-medium text-secondary">{order.shippingAddress.name}</p>
            <p>{order.shippingAddress.street}, {order.shippingAddress.number}</p>
            {order.shippingAddress.complement && (
              <p>{order.shippingAddress.complement}</p>
            )}
            <p>{order.shippingAddress.postalCode} {order.shippingAddress.city}</p>
            <p>{order.shippingAddress.district}</p>
            <p>{order.shippingAddress.country}</p>
            <p>Telefone: {order.shippingAddress.phone}</p>
            <p>Email: {order.shippingAddress.email}</p>
            {order.shippingAddress.taxNumber && (
              <p>NIF: {order.shippingAddress.taxNumber}</p>
            )}
          </div>
        </div>

        {/* Multibanco Payment Info */}
        {order.paymentMethod === 'multibanco' && order.status === 'awaiting_payment' && payment && (
          <div className="bg-white shadow-md rounded-lg p-6 mb-8">
            <div className="flex items-center justify-center space-x-2 mb-4">
              <Landmark className="w-6 h-6 text-primary" />
              <h2 className="text-xl font-semibold text-secondary">Dados para Pagamento Multibanco</h2>
            </div>
            <div className="grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg">
              <div>
                <p className="text-sm text-gray-500">Entidade</p>
                <p className="text-2xl font-mono font-bold text-secondary">{payment.entity}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Referência</p>
                <p className="text-2xl font-mono font-bold text-secondary">{payment.reference}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Valor</p>
                <p className="text-2xl font-mono font-bold text-secondary">{Number(payment.amount).toFixed(2)} €</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Válido até</p>
                <p className="text-lg font-bold text-red-600">
                  {formatDate(payment.expiryDate)}
                </p>
              </div>
            </div>
            <p className="text-sm text-red-500 mt-4">
              ⚠️ IMPORTANTE: Tem apenas 2 dias de calendário para efetuar o pagamento.
              Após este prazo, a encomenda será automaticamente cancelada.
            </p>
          </div>
        )}

        {/* MBWAY Payment Info */}
        {order.paymentMethod === 'mbway' && order.status === 'awaiting_payment' && (
          <div className="bg-white shadow-md rounded-lg p-6 mb-8">
            <h2 className="text-xl font-semibold text-secondary mb-4">Pagamento MBWay</h2>
            <p className="text-gray-600">
              Foi enviada uma notificação para o seu telemóvel MBWay.
              Por favor, aceite o pagamento na aplicação MBWay.
            </p>
            <MBWayCountdown orderId={order.id} />
          </div>
        )}

        <div className="mt-8 text-center">
          <Link
            to="/client-area"
            className="text-primary hover:text-primary-dark underline"
          >
            Ver minhas encomendas
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmationPage;