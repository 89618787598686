import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product } from '../types';

interface FavoritesContextType {
  favorites: Product[];
  toggleFavorite: (product: Product) => void;
  isFavorite: (productId: string) => boolean;
}

const FavoritesContext = createContext<FavoritesContextType>({
  favorites: [],
  toggleFavorite: () => {},
  isFavorite: () => false,
});

export const useFavorites = () => useContext(FavoritesContext);

export const FavoritesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [favorites, setFavorites] = useState<Product[]>([]);
  const { user } = useAuth();

  // Carregar favoritos do usuário
  useEffect(() => {
    const loadFavorites = async () => {
      if (!user) {
        setFavorites([]);
        return;
      }

      try {
        const userFavoritesRef = doc(db, 'userFavorites', user.uid);
        const docSnap = await getDoc(userFavoritesRef);
        
        if (docSnap.exists()) {
          setFavorites(docSnap.data().favorites || []);
        } else {
          // Criar documento vazio se não existir
          await setDoc(userFavoritesRef, { favorites: [] });
          setFavorites([]);
        }
      } catch (error) {
        console.error('Erro ao carregar favoritos:', error);
        setFavorites([]);
      }
    };

    loadFavorites();
  }, [user]);

  // Salvar favoritos quando mudarem
  useEffect(() => {
    const saveFavorites = async () => {
      if (!user) return;

      try {
        const userFavoritesRef = doc(db, 'userFavorites', user.uid);
        await setDoc(userFavoritesRef, { favorites });
      } catch (error) {
        console.error('Erro ao salvar favoritos:', error);
      }
    };

    if (user) {
      saveFavorites();
    }
  }, [favorites, user]);

  const toggleFavorite = (product: Product) => {
    setFavorites(currentFavorites => {
      const exists = currentFavorites.some(fav => fav.id === product.id);
      
      if (exists) {
        return currentFavorites.filter(fav => fav.id !== product.id);
      } else {
        return [...currentFavorites, product];
      }
    });
  };

  const isFavorite = (productId: string) => {
    return favorites.some(fav => fav.id === productId);
  };

  return (
    <FavoritesContext.Provider value={{ favorites, toggleFavorite, isFavorite }}>
      {children}
    </FavoritesContext.Provider>
  );
};
