import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import ProductCard from '../components/ProductCard';
import { Loader } from 'lucide-react';
import { slugify } from '../utils/slugify';

interface GroupedProducts {
  [ean: string]: any[];
}

const CategoryProductsPage = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [categoryName, setCategoryName] = useState<string>('');

  useEffect(() => {
    const fetchProducts = async () => {
      if (!id) return;
      
      setIsLoading(true);
      setError(null);
      
      try {
        // Convert slug to possible category names
        const possibleNames = [
          // Original slug
          id,
          // Simple capitalization: "jogos" -> "Jogos"
          id.charAt(0).toUpperCase() + id.slice(1),
          // Hyphenated words: "veloes-ritualizados" -> "Veloes Ritualizados"
          id.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
          // Replace hyphens with spaces: "veloes-ritualizados" -> "veloes ritualizados"
          id.replace(/-/g, ' ')
        ];
        
        let productsData = [];
        const productsRef = collection(db, 'products');

        // Try each possible category name
        for (const name of possibleNames) {
          const q = query(
            productsRef, 
            where('category', '==', name),
            where('isPrivateSale', '==', true)
          );
          const snapshot = await getDocs(q);
          const docs = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          if (docs.length > 0) {
            productsData = docs;
            setCategoryName(name);
            break;
          }
        }

        // If still no products found, try a case-insensitive search
        if (productsData.length === 0) {
          const q = query(
            productsRef,
            where('isPrivateSale', '==', true)
          );
          const allProductsSnapshot = await getDocs(q);
          const allProducts = allProductsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          // Filter products where category matches case-insensitive
          productsData = allProducts.filter(product => {
            const productCategory = product.category?.toLowerCase();
            return possibleNames.some(name => 
              productCategory === name.toLowerCase() ||
              slugify(productCategory) === slugify(name)
            );
          });

          if (productsData.length > 0) {
            setCategoryName(productsData[0].category);
          }
        }

        // Filter products with stock
        const availableProducts = productsData.filter(product => product.stockQuantity > 0);
        setProducts(availableProducts);
      } catch (err) {
        setError('Erro ao carregar produtos. Por favor, tente novamente mais tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [id]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center text-red-500">{error}</div>
      </div>
    );
  }

  if (products.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-secondary mb-8">
          {categoryName || id.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}
        </h1>
        <div className="text-center text-gray-500">
          Nenhum produto encontrado nesta categoria.
        </div>
      </div>
    );
  }

  // Group products by EAN
  const groupedProducts = products.reduce((acc: GroupedProducts, product: any) => {
    if (!acc[product.ean]) {
      acc[product.ean] = [];
    }
    acc[product.ean].push(product);
    return acc;
  }, {});

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-secondary mb-8">
        {categoryName || products[0]?.category || id.split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {Object.values(groupedProducts).map((products: any) => (
          <ProductCard key={products[0].id} product={products[0]} />
        ))}
      </div>
    </div>
  );
};

export default CategoryProductsPage;