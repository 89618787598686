import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { ShoppingBag } from 'lucide-react';

const CartPage = () => {
  const navigate = useNavigate();
  const { items, removeItem, updateQuantity, getTotal } = useCart();

  const handleCheckout = () => {
    if (items && items.length > 0) {
      navigate('/checkout');
    }
  };

  const formatPrice = (price: number) => {
    return !isNaN(price) ? price.toFixed(2) : '0.00';
  };

  if (!items || items.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center">
          <ShoppingBag className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">Carrinho vazio</h3>
          <p className="mt-1 text-sm text-gray-500">
            Comece a adicionar produtos ao seu carrinho
          </p>
          <div className="mt-6">
            <Link
              to="/"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Continuar a comprar
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const subtotal = getTotal();

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Carrinho de Compras</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
        <div className="lg:col-span-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            {items.map((item) => {
              const itemTotal = item.quantity * Number(item.product.price);
              return (
                <div key={item.product.id} className="p-6 border-b last:border-b-0">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center flex-1">
                      <img
                        src={item.product.image}
                        alt={item.product.name}
                        className="w-24 h-24 object-cover rounded-md mr-4"
                      />
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">
                          {item.product.name}
                        </h3>
                        <p className="text-sm text-gray-500">SKU: {item.product.sku}</p>
                        <div className="mt-2 flex items-center space-x-4">
                          <div className="flex items-center border rounded-md">
                            <button
                              onClick={() => updateQuantity(item.product.id, Math.max(1, item.quantity - 1))}
                              className="px-3 py-1 text-gray-500 hover:text-gray-700 border-r"
                            >
                              -
                            </button>
                            <span className="px-3 py-1">
                              {item.quantity}
                            </span>
                            <button
                              onClick={() => updateQuantity(item.product.id, item.quantity + 1)}
                              className="px-3 py-1 text-gray-500 hover:text-gray-700 border-l"
                            >
                              +
                            </button>
                          </div>
                          <button
                            onClick={() => removeItem(item.product.id)}
                            className="text-red-500 hover:text-red-700 text-sm font-medium"
                          >
                            Remover
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="text-lg font-medium text-gray-900">
                        €{formatPrice(itemTotal)}
                      </p>
                      <div className="mt-1 flex items-center text-sm text-gray-500">
                        <img 
                          src={item.product.sellerLogo} 
                          alt={item.product.sellerName}
                          className="w-4 h-4 rounded-full mr-1"
                        />
                        <span>{item.product.sellerName}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="lg:col-span-4">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Resumo da Encomenda</h2>
            <div className="flex justify-between mb-2">
              <span className="text-gray-600">Subtotal</span>
              <span className="text-gray-900 font-medium">€{formatPrice(subtotal)}</span>
            </div>
            <div className="border-t border-gray-200 my-4"></div>
            <div className="flex justify-between mb-4">
              <span className="text-gray-900 font-medium">Total</span>
              <span className="text-gray-900 font-medium">€{formatPrice(subtotal)}</span>
            </div>
            <button
              onClick={handleCheckout}
              className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Finalizar Compra
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;