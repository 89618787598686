import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Store, 
  Building2, 
  RefreshCw, 
  ShoppingBag, 
  Truck, 
  HeadsetIcon, 
  Receipt, 
  Globe,
  Percent,
  Euro,
  Users,
  Clock,
  Wallet
} from 'lucide-react';

const VendedorPage = () => {
  const benefits = [
    {
      icon: <Building2 className="w-8 h-8 text-primary" />,
      title: 'Marketplace B2B',
      description: 'Acede à área exclusiva de vendas entre vendedores do EMTUACASA e expande o teu negócio B2B.'
    },
    {
      icon: <Users className="w-8 h-8 text-primary" />,
      title: 'Rede Social',
      description: 'Conecta-te com outros vendedores, partilha experiências e cria parcerias através da nossa rede social.'
    },
    {
      icon: <Clock className="w-8 h-8 text-primary" />,
      title: 'Envios 24/48h',
      description: 'As recolhas e entregas são asseguradas pela SHIPPIX, garantindo rapidez e eficiência nas tuas encomendas.'
    },
    {
      icon: <Wallet className="w-8 h-8 text-primary" />,
      title: 'Wallet Integrada',
      description: 'O valor das tuas vendas fica na wallet. Usa para compras B2B ou transfere diretamente para a tua conta bancária.'
    },
    {
      icon: <RefreshCw className="w-8 h-8 text-primary" />,
      title: 'Sincronização Total',
      description: 'Integra e sincroniza a tua loja online existente com a nossa plataforma automaticamente.'
    },
    {
      icon: <ShoppingBag className="w-8 h-8 text-primary" />,
      title: 'Gestão Centralizada',
      description: 'Faz a gestão de todas as tuas encomendas do EMTUACASA e da tua loja online num único local.'
    },
    {
      icon: <Globe className="w-8 h-8 text-primary" />,
      title: 'Fulfillment Integrado',
      description: 'Integração com Amazon, Fnac, Worten e KuantoKusta para maximizar as tuas vendas.'
    },
    {
      icon: <Percent className="w-8 h-8 text-primary" />,
      title: 'Comissão Competitiva',
      description: 'Apenas 15%+IVA por venda, sem custos ocultos.'
    },
    {
      icon: <Truck className="w-8 h-8 text-primary" />,
      title: 'Envios Gratuitos',
      description: 'Custos de envio suportados pelos clientes, sem despesas para ti.'
    },
    {
      icon: <HeadsetIcon className="w-8 h-8 text-primary" />,
      title: 'Suporte Dedicado',
      description: 'Equipa especializada para te ajudar em todas as etapas do teu negócio.'
    },
    {
      icon: <Receipt className="w-8 h-8 text-primary" />,
      title: 'Faturação Integrada',
      description: 'Sistema de faturação simplificado e totalmente integrado na plataforma.'
    },
    {
      icon: <Store className="w-8 h-8 text-primary" />,
      title: 'Página Personalizada',
      description: 'Página dedicada ao teu negócio com URL personalizado para fortalecer a tua marca.'
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative">
        {/* Background Image */}
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1566576912321-d58ddd7a6088?q=80&w=2070&auto=format&fit=crop"
            alt="Fulfillment Background"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-60"></div>
        </div>

        {/* Content */}
        <div className="relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <div className="text-center">
              <h1 className="text-4xl font-bold mb-6 text-white">
                EMTUACASA: A Tua Solução All-in-One para E-commerce
              </h1>
              <p className="text-xl mb-8 max-w-3xl mx-auto text-gray-100">
                Faz a gestão das tuas vendas online, B2B e marketplace numa única plataforma. 
                Integração completa com os principais marketplaces e sistema de fulfillment.
              </p>
              <div className="flex justify-center items-center space-x-8">
                <a
                  href="https://controlo.emtuacasa.pt/register"
                  className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-md shadow-lg text-primary bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-all duration-200"
                >
                  Começar agora
                </a>
                <div className="text-white text-left">
                  <div className="font-bold text-3xl flex items-center">
                    <Euro className="w-7 h-7 mr-1" />
                    29,99
                    <span className="text-lg ml-1">/mês</span>
                  </div>
                  <div className="text-gray-200">3 meses de fidelização</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-16">
          Tudo o que precisas para vender online
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {benefits.map((benefit, index) => (
            <div key={index} className="bg-gray-50 p-6 rounded-lg">
              <div className="flex items-center mb-4">
                {benefit.icon}
                <h3 className="text-xl font-semibold text-gray-900 ml-3">
                  {benefit.title}
                </h3>
              </div>
              <p className="text-gray-600">
                {benefit.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="relative overflow-hidden rounded-2xl">
            <div className="absolute inset-0 bg-gradient-to-r from-[#9BAD2D] to-[#C3D941]"></div>
            
            <div className="relative p-8 md:p-12 text-center">
              <h2 className="text-3xl font-bold mb-4 text-white drop-shadow-md">
                Começa a vender hoje mesmo
              </h2>
              <p className="text-xl mb-8 max-w-2xl mx-auto text-white drop-shadow-md">
                Junta-te aos vendedores de sucesso do EMTUACASA e expande o teu negócio
              </p>
              <div className="space-y-4">
                <a
                  href="https://controlo.emtuacasa.pt/register"
                  className="inline-flex items-center px-8 py-3 border-2 border-white text-base font-medium rounded-md bg-white hover:bg-gray-50 text-[#9BAD2D] hover:text-[#C3D941] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#9BAD2D] transition-all duration-200 shadow-lg"
                >
                  Criar a tua conta
                </a>
                <div className="text-white text-sm mt-4 font-medium drop-shadow-md">
                  Apenas €29,99/mês com 3 meses de fidelização
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendedorPage;
