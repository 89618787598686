import { initializeApp, getApps, getApp } from 'firebase/app';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  sendPasswordResetEmail as sendResetEmail,
  signOut,
  updateProfile,
  onAuthStateChanged,
  browserLocalPersistence,
  setPersistence,
  type User
} from 'firebase/auth';
import { 
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  DocumentData,
  QueryDocumentSnapshot,
  serverTimestamp,
  increment,
  arrayUnion,
  arrayRemove,
  addDoc,
  onSnapshot
} from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref as dbRef, onValue, set } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAZBn-bz1Sk8dbK7AYnwx02Q6A76Q0Ceak",
  authDomain: "lognow-controlo.firebaseapp.com",
  databaseURL: "https://lognow-controlo-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lognow-controlo",
  storageBucket: "lognow-controlo.appspot.com",
  messagingSenderId: "41207915465",
  appId: "1:41207915465:web:8db3b0a910d67be7697e18",
  databaseURL: "https://lognow-controlo-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase only if it hasn't been initialized yet
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const database = getDatabase(app);

// Set persistence to LOCAL
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error('Error setting persistence:', error);
});

// Auth functions
const registerUser = async (email: string, password: string, displayName: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Update profile
    await updateProfile(user, {
      displayName: displayName
    });

    // Create user document
    await setDoc(doc(db, 'users', user.uid), {
      email: email,
      displayName: displayName,
      createdAt: serverTimestamp(),
      role: 'customer'
    });

    return user;
  } catch (error) {
    throw error;
  }
};

const loginWithEmail = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

const sendPasswordResetEmail = async (email: string) => {
  try {
    await sendResetEmail(auth, email);
  } catch (error) {
    throw error;
  }
};

const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw error;
  }
};

// Helper function to generate order number
const generateOrderNumber = async () => {
  const counterRef = doc(db, 'counters', 'orders');
  const counterDoc = await getDoc(counterRef);
  
  let counter = 1;
  if (counterDoc.exists()) {
    counter = counterDoc.data().current + 1;
  }
  
  await setDoc(counterRef, { current: counter });
  return `PS${counter.toString().padStart(10, '0')}`;
};

// Create order function
const createOrder = async (orderData: any) => {
  try {
    const orderRef = doc(collection(db, 'orders'));
    const timestamp = new Date();

    // Check if using wallet and determine payment method
    const useWallet = orderData.useWallet && orderData.walletAmount > 0;
    const isFullWalletPayment = useWallet && orderData.walletAmount >= orderData.total;
    
    const paymentMethod = isFullWalletPayment ? 'wallet' : orderData.paymentMethod;
    const initialStatus = isFullWalletPayment ? 'pending' : 
      (paymentMethod === 'multibanco' || paymentMethod === 'mbway' ? 'awaiting_payment' : 'pending');

    // Get buyer's wallet if using wallet
    if (useWallet) {
      const buyerWalletRef = doc(db, 'wallets', orderData.buyerId);
      const buyerWalletDoc = await getDoc(buyerWalletRef);
      
      if (!buyerWalletDoc.exists()) {
        throw new Error('Wallet não encontrada');
      }

      const currentBalance = buyerWalletDoc.data().balance;
      if (currentBalance < orderData.walletAmount) {
        throw new Error('Saldo insuficiente na wallet');
      }

      // Update buyer's wallet
      await updateDoc(buyerWalletRef, { 
        balance: currentBalance - orderData.walletAmount,
        updatedAt: timestamp.toISOString()
      });

      // Record wallet transaction
      await addDoc(collection(db, 'walletTransactions'), {
        userId: orderData.buyerId,
        orderId: orderRef.id,
        amount: -orderData.walletAmount,
        type: 'order_payment',
        description: `Pagamento parcial da encomenda`,
        createdAt: timestamp.toISOString(),
        status: 'completed'
      });
    }

    // Create order
    const order = {
      ...orderData,
      id: orderRef.id,
      paymentMethod,
      status: initialStatus,
      createdAt: timestamp.toISOString(),
      updatedAt: timestamp.toISOString()
    };

    await setDoc(orderRef, order);
    return orderRef.id;
  } catch (error) {
    console.error('Erro ao criar encomenda:', error);
    throw error;
  }
};

// Add product review function
const addProductReview = async (productId: string, reviewData: {
  userId: string;
  userName: string;
  rating: number;
  comment: string;
}) => {
  try {
    const reviewRef = doc(collection(db, `products/${productId}/reviews`));
    const timestamp = new Date();

    const review = {
      id: reviewRef.id,
      ...reviewData,
      createdAt: timestamp.toISOString(),
      updatedAt: timestamp.toISOString()
    };

    await setDoc(reviewRef, review);

    // Update product rating
    const productRef = doc(db, 'products', productId);
    const productDoc = await getDoc(productRef);
    
    if (productDoc.exists()) {
      const product = productDoc.data();
      const currentRating = product.rating || 0;
      const totalReviews = product.totalReviews || 0;
      
      const newRating = ((currentRating * totalReviews) + reviewData.rating) / (totalReviews + 1);
      
      await updateDoc(productRef, {
        rating: newRating,
        totalReviews: increment(1),
        updatedAt: timestamp.toISOString()
      });
    }

    return reviewRef.id;
  } catch (error) {
    console.error('Erro ao adicionar review:', error);
    throw error;
  }
};

// Add order comment function
const addOrderComment = async (orderId: string, comment: {
  text: string;
  createdBy: string;
}) => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const timestamp = new Date();

    const newComment = {
      id: Date.now().toString(),
      ...comment,
      createdAt: timestamp.toISOString()
    };

    await updateDoc(orderRef, {
      comments: arrayUnion(newComment),
      updatedAt: timestamp.toISOString()
    });

    return newComment.id;
  } catch (error) {
    console.error('Erro ao adicionar comentário:', error);
    throw error;
  }
};

// Upload image function
const uploadImage = async (file: File, path: string) => {
  try {
    const imageRef = storageRef(storage, path);
    await uploadBytes(imageRef, file);
    const url = await getDownloadURL(imageRef);
    return url;
  } catch (error) {
    console.error('Erro ao fazer upload da imagem:', error);
    throw error;
  }
};

// Generate Multibanco payment
const generateMultibancoPayment = async (orderId: string, amount: number) => {
  try {
    const generatePayment = httpsCallable(functions, 'generateMultibancoPayment');
    const result = await generatePayment({ orderId, amount });
    return result.data;
  } catch (error) {
    console.error('Erro ao gerar referência Multibanco:', error);
    throw error;
  }
};

// Generate MBWay payment
const generateMBWayPayment = async (orderId: string, amount: number, phone: string) => {
  try {
    const generatePayment = httpsCallable(functions, 'generateMBWayPayment');
    const result = await generatePayment({ orderId, amount, phone });
    return result.data;
  } catch (error) {
    console.error('Erro ao gerar pagamento MBWay:', error);
    throw error;
  }
};

// Payment related functions
const retryMBWayPayment = async (orderId: string, phone: string) => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (!orderDoc.exists()) {
      throw new Error('Pedido não encontrado');
    }

    const orderData = orderDoc.data();
    const amount = orderData.total - (orderData.walletAmount || 0);

    const generatePayment = httpsCallable(functions, 'generateMBWayPayment');
    const result = await generatePayment({ orderId, amount, phone });

    await updateDoc(orderRef, {
      'paymentDetails.phone': phone,
      updatedAt: new Date().toISOString()
    });

    return result.data;
  } catch (error) {
    console.error('Erro ao retentar pagamento MBWay:', error);
    throw error;
  }
};

const retryMultibancoPayment = async (orderId: string) => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (!orderDoc.exists()) {
      throw new Error('Pedido não encontrado');
    }

    const orderData = orderDoc.data();
    const amount = orderData.total - (orderData.walletAmount || 0);

    const generatePayment = httpsCallable(functions, 'generateMultibancoPayment');
    const result = await generatePayment({ orderId, amount });

    await updateDoc(orderRef, {
      updatedAt: new Date().toISOString()
    });

    return result.data;
  } catch (error) {
    console.error('Erro ao retentar pagamento Multibanco:', error);
    throw error;
  }
};

const checkPaymentStatus = async (orderId: string) => {
  try {
    const checkStatus = httpsCallable(functions, 'checkPaymentStatus');
    const result = await checkStatus({ orderId });
    return result.data;
  } catch (error) {
    console.error('Erro ao verificar status do pagamento:', error);
    throw error;
  }
};

// Payment query functions
const getMultibancoPayment = async (orderId: string) => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (!orderDoc.exists()) {
      throw new Error('Pedido não encontrado');
    }

    const orderData = orderDoc.data();
    if (orderData.paymentMethod !== 'multibanco') {
      throw new Error('Método de pagamento não é Multibanco');
    }

    return {
      orderId,
      entity: orderData.paymentDetails?.entity,
      reference: orderData.paymentDetails?.reference,
      amount: orderData.total - (orderData.walletAmount || 0),
      status: orderData.status
    };
  } catch (error) {
    console.error('Erro ao buscar pagamento Multibanco:', error);
    throw error;
  }
};

const getMBWayPayment = async (orderId: string) => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (!orderDoc.exists()) {
      throw new Error('Pedido não encontrado');
    }

    const orderData = orderDoc.data();
    if (orderData.paymentMethod !== 'mbway') {
      throw new Error('Método de pagamento não é MBWay');
    }

    return {
      orderId,
      phone: orderData.paymentDetails?.phone,
      amount: orderData.total - (orderData.walletAmount || 0),
      status: orderData.status
    };
  } catch (error) {
    console.error('Erro ao buscar pagamento MBWay:', error);
    throw error;
  }
};

const getPaymentHistory = async (orderId: string) => {
  try {
    const paymentsRef = collection(db, `orders/${orderId}/payments`);
    const paymentsSnapshot = await getDocs(paymentsRef);
    
    return paymentsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Erro ao buscar histórico de pagamentos:', error);
    throw error;
  }
};

// Wallet functions
const createWallet = async (userId: string, companyId: string = '') => {
  try {
    const walletRef = doc(db, 'wallets', userId);
    const wallet = {
      id: userId,
      balance: 0,
      companyId,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };
    await setDoc(walletRef, wallet);
    return wallet;
  } catch (error) {
    console.error('Erro ao criar wallet:', error);
    throw error;
  }
};

const getWallet = async (userId: string) => {
  try {
    const walletRef = doc(db, 'wallets', userId);
    const walletDoc = await getDoc(walletRef);
    if (!walletDoc.exists()) {
      return null;
    }
    return { id: walletDoc.id, ...walletDoc.data() };
  } catch (error) {
    console.error('Erro ao buscar wallet:', error);
    throw error;
  }
};

const updateWalletBalance = async (userId: string, amount: number, description: string) => {
  try {
    const walletRef = doc(db, 'wallets', userId);
    const timestamp = new Date();

    await updateDoc(walletRef, {
      balance: increment(amount),
      updatedAt: timestamp.toISOString()
    });

    // Record transaction
    await addDoc(collection(db, 'walletTransactions'), {
      userId,
      amount,
      type: amount > 0 ? 'credit' : 'debit',
      description,
      createdAt: timestamp.toISOString(),
      status: 'completed'
    });
  } catch (error) {
    console.error('Erro ao atualizar saldo da wallet:', error);
    throw error;
  }
};

// Export everything we'll need
export {
  auth,
  db,
  storage,
  functions,
  database,
  registerUser,
  loginWithEmail,
  sendPasswordResetEmail,
  logout,
  onAuthStateChanged,
  type User,
  createOrder,
  addProductReview,
  addOrderComment,
  uploadImage,
  generateMultibancoPayment,
  generateMBWayPayment,
  retryMBWayPayment,
  retryMultibancoPayment,
  checkPaymentStatus,
  getMultibancoPayment,
  getMBWayPayment,
  getPaymentHistory,
  createWallet,
  getWallet,
  updateWalletBalance,
  onSnapshot,
  collection,
  doc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  increment,
  arrayUnion,
  arrayRemove
};

export default app;