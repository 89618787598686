import { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product, ProductWithSellers } from '../types';

// Verificar promoção válida
const hasValidPromo = (p: Product) => {
  if (!p?.promotionalPeriod?.startDate || !p?.promotionalPeriod?.endDate) return false;
  if (!p?.salePrice) return false;
  
  const startDate = new Date(p.promotionalPeriod.startDate);
  const endDate = new Date(p.promotionalPeriod.endDate);
  const now = new Date();
  
  return startDate <= now && endDate >= now && Number(p.salePrice) < Number(p.regularPrice);
};

export const useProducts = (limitCount: number = 8, promotionalOnly: boolean = false): { products: ProductWithSellers[], error: string | null } => {
  const [products, setProducts] = useState<ProductWithSellers[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const q = query(
          collection(db, 'products'), 
          where('isPrivateSale', '==', true),
          orderBy('createdAt', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const allProducts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Product[];

        // Filtrar produtos válidos e agrupar por identificador único
        const productsByKey = allProducts.reduce((acc, product) => {
          if (product?.stock > 0 && Number(product?.regularPrice) > 0) {
            // Usar EAN, SKU ou ID como chave única
            const key = product.ean || product.sku || product.id;
            if (!acc[key]) acc[key] = [];
            acc[key].push(product);
          }
          return acc;
        }, {} as Record<string, Product[]>);

        // Para cada grupo de produtos, escolher o melhor preço
        const bestProducts = Object.values(productsByKey).map(products => {
          // Encontrar o melhor preço entre todos os vendedores
          const bestSeller = products.reduce((best, current) => {
            const bestPrice = hasValidPromo(best) ? Number(best.salePrice) : Number(best.regularPrice);
            const currentPrice = hasValidPromo(current) ? Number(current.salePrice) : Number(current.regularPrice);
            
            // Se os preços são iguais, manter o vendedor original
            if (currentPrice === bestPrice) {
              return best.createdAt < current.createdAt ? best : current;
            }
            
            return currentPrice < bestPrice ? current : best;
          });

          // Retornar o produto com o melhor preço
          return {
            ...bestSeller,
            otherSellers: products.length - 1,
            displayPrice: hasValidPromo(bestSeller) ? bestSeller.salePrice : bestSeller.regularPrice
          };
        });

        // Se promotionalOnly é true, filtrar apenas produtos em promoção
        const filteredProducts = promotionalOnly 
          ? bestProducts.filter(hasValidPromo)
          : bestProducts;

        setProducts(filteredProducts.slice(0, limitCount));
        setError(null);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Erro ao carregar produtos');
        setProducts([]);
      }
    };

    fetchProducts();
  }, [limitCount, promotionalOnly]);

  return { products, error };
};