import React from 'react';
import { Pencil, Trash2 } from 'lucide-react';

interface Address {
  id: string;
  name: string;
  street: string;
  number: string;
  complement?: string;
  postalCode: string;
  city: string;
  district: string;
  phone: string;
  email: string;
  taxNumber?: string;
  isDefault?: boolean;
}

interface AddressSelectorProps {
  addresses: Address[];
  selectedAddressId: string | null;
  onSelect: (address: Address) => void;
  onAddNew: () => void;
  onEdit: (address: Address) => void;
  onDelete: (addressId: string) => void;
}

const AddressSelector: React.FC<AddressSelectorProps> = ({
  addresses,
  selectedAddressId,
  onSelect,
  onAddNew,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="space-y-4">
      {addresses.length > 0 ? (
        <div className="space-y-4">
          {addresses.map((address) => (
            <div
              key={address.id}
              className={`border rounded-lg p-4 cursor-pointer relative ${
                selectedAddressId === address.id
                  ? 'border-primary bg-primary/5'
                  : 'border-gray-200 hover:border-gray-300'
              }`}
              onClick={() => onSelect(address)}
            >
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <div className="text-secondary">
                    <p className="font-medium">{address.name}</p>
                    <p>{address.street}, {address.number}</p>
                    {address.complement && <p>{address.complement}</p>}
                    <p>{address.postalCode} {address.city}</p>
                    <p>{address.district}</p>
                    <p>Telefone: {address.phone}</p>
                    <p>Email: {address.email}</p>
                    {address.taxNumber && <p>NIF: {address.taxNumber}</p>}
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(address);
                    }}
                    className="p-2 text-gray-500 hover:text-primary rounded-full hover:bg-gray-100"
                  >
                    <Pencil size={16} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(address.id);
                    }}
                    className="p-2 text-gray-500 hover:text-red-500 rounded-full hover:bg-gray-100"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">Nenhuma morada de entrega registada</p>
      )}

      <button
        onClick={onAddNew}
        className="w-full py-3 px-4 border border-dashed border-gray-300 rounded-lg text-primary hover:text-primary-dark hover:border-primary-dark flex items-center justify-center"
      >
        + Adicionar nova morada
      </button>
    </div>
  );
};

export default AddressSelector;