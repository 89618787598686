import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

export const useShippingCosts = () => {
  const [costs, setCosts] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, 'settings/shipping_costs'),
      (doc) => {
        if (doc.exists()) {
          // Normalizar os distritos para lowercase
          const rawCosts = doc.data().costs || {};
          const normalizedCosts: Record<string, number> = {};
          
          Object.entries(rawCosts).forEach(([district, cost]) => {
            normalizedCosts[district.toLowerCase().trim()] = Number(cost);
          });
          
          setCosts(normalizedCosts);
        }
        setLoading(false);
      },
      (err) => {
        setError('Erro ao carregar custos de envio');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { costs, loading, error };
};