import React from 'react';
import { Link } from 'react-router-dom';
import { ShoppingCart, Heart, User, Home } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';

const MobileNavigation: React.FC = () => {
  const { user } = useAuth();
  const { items, getTotal } = useCart();
  const cartTotal = getTotal();
  const hasCartItems = items.length > 0;

  return (
    <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-[#1B3B68] text-white border-t border-[#144272] z-50">
      <div className="flex justify-around items-center h-16">
        <Link 
          to="/" 
          className="flex flex-col items-center justify-center space-y-1 w-1/4 hover:bg-[#2c4f82] py-2"
        >
          <Home className="h-6 w-6" />
          <span className="text-xs">Início</span>
        </Link>

        <Link 
          to="/favorites" 
          className="flex flex-col items-center justify-center space-y-1 w-1/4 hover:bg-[#2c4f82] py-2"
        >
          <Heart className="h-6 w-6" />
          <span className="text-xs">Favoritos</span>
        </Link>

        <Link 
          to="/cart" 
          className="flex flex-col items-center justify-center space-y-1 w-1/4 hover:bg-[#2c4f82] py-2 relative"
        >
          <div className="relative">
            <ShoppingCart className="h-6 w-6" />
            {hasCartItems && (
              <div className="absolute -top-2 -right-2 bg-primary text-white text-xs px-1.5 py-0.5 rounded-full">
                €{cartTotal.toFixed(2)}
              </div>
            )}
          </div>
          <span className="text-xs">Carrinho</span>
        </Link>

        <Link 
          to={user ? '/client-area' : '/login'} 
          className="flex flex-col items-center justify-center space-y-1 w-1/4 hover:bg-[#2c4f82] py-2"
        >
          <User className="h-6 w-6" />
          <span className="text-xs">{user ? 'Conta' : 'Login'}</span>
        </Link>
      </div>
    </div>
  );
};

export default MobileNavigation;
