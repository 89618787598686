import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import { FavoritesProvider } from './contexts/FavoritesContext';
import { ToastContainer } from 'react-toastify';
import InstallPWAPrompt from './components/InstallPWAPrompt';
import MaintenanceCheck from './components/MaintenanceCheck';
import AppRoutes from './routes';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <CartProvider>
            <FavoritesProvider>
              <MaintenanceCheck>
                <div className="min-h-screen flex flex-col">
                  <ToastContainer />
                  <AppRoutes />
                  <InstallPWAPrompt />
                </div>
              </MaintenanceCheck>
            </FavoritesProvider>
          </CartProvider>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;