import { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Category } from '../types';
import { slugify } from '../utils/slugify';
import { getIconFromName } from '../utils/categoryIcons';

export const useCategories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const categoriesRef = collection(db, 'categories');
        const productsRef = collection(db, 'products');
        
        // Primeiro, buscar todas as categorias
        const categoriesSnapshot = await getDocs(categoriesRef);
        const allCategories = categoriesSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            icon: getIconFromName(data.name || '')
          };
        }) as Category[];

        // Depois, buscar produtos que estão em private sale
        const productsSnapshot = await getDocs(
          query(productsRef, where('isPrivateSale', '==', true))
        );
        
        const products = productsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Criar um Set com os IDs das categorias que têm produtos
        const categoriesWithProducts = new Set(
          products
            .filter(product => product.category) // Garantir que tem categoria
            .map(product => {
              // Tentar encontrar a categoria pelo nome
              const category = allCategories.find(c => 
                c.name.toLowerCase() === product.category?.toLowerCase()
              );
              return category?.id;
            })
            .filter(id => id) // Remover undefined
        );

        // Filtrar apenas as categorias que têm produtos
        const validCategories = allCategories
          .filter(category => {
            const hasProducts = categoriesWithProducts.has(category.id);
            return hasProducts;
          })
          .sort((a, b) => a.name.localeCompare(b.name));
        
        setCategories(validCategories);
      } catch (err) {
        console.error('Error fetching categories:', err);
        setError('Erro ao carregar categorias');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return { categories, loading, error };
};