import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BaseLayout from './layouts/BaseLayout';
import CartAwareLayout from './layouts/CartAwareLayout';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import FavoritesPage from './pages/FavoritesPage';
import CategoriesPage from './pages/CategoriesPage';
import CategoryProductsPage from './pages/CategoryProductsPage';
import SellerProfilePage from './pages/SellerProfilePage';
import ClientArea from './pages/ClientArea';
import WelcomePage from './pages/WelcomePage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import TrackOrderPage from './pages/TrackOrderPage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import SearchPage from './pages/SearchPage';
import VendedorPage from './pages/VendedorPage';
import MaintenancePage from './pages/maintenance';
import Sitemap from './pages/Sitemap';
import KuantoKustaFeed from './pages/KuantoKustaFeed';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Rotas que não precisam do contexto do carrinho */}
      <Route 
        path="/maintenance" 
        element={
          <BaseLayout noLayout>
            <MaintenancePage />
          </BaseLayout>
        } 
      />
      <Route 
        path="/sitemap.xml" 
        element={
          <BaseLayout noLayout>
            <Sitemap />
          </BaseLayout>
        } 
      />
      <Route 
        path="/kuantokusta" 
        element={
          <BaseLayout noLayout>
            <KuantoKustaFeed />
          </BaseLayout>
        } 
      />
      <Route 
        path="/feed/kuantokusta.xml" 
        element={
          <BaseLayout noLayout>
            <KuantoKustaFeed />
          </BaseLayout>
        } 
      />

      {/* Rotas que precisam do contexto do carrinho */}
      <Route 
        path="/" 
        element={
          <CartAwareLayout>
            <HomePage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/product/:id" 
        element={
          <CartAwareLayout>
            <ProductPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/register" 
        element={
          <CartAwareLayout>
            <RegisterPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/login" 
        element={
          <CartAwareLayout>
            <LoginPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/cart" 
        element={
          <CartAwareLayout>
            <CartPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/checkout" 
        element={
          <CartAwareLayout>
            <CheckoutPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/favorites" 
        element={
          <CartAwareLayout>
            <FavoritesPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/categories" 
        element={
          <CartAwareLayout>
            <CategoriesPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/category/:id" 
        element={
          <CartAwareLayout>
            <CategoryProductsPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/seller/:id" 
        element={
          <CartAwareLayout>
            <SellerProfilePage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/client-area/*" 
        element={
          <CartAwareLayout>
            <ClientArea />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/welcome" 
        element={
          <CartAwareLayout>
            <WelcomePage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/order/:id" 
        element={
          <CartAwareLayout>
            <OrderDetailsPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/terms" 
        element={
          <CartAwareLayout>
            <TermsPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/privacy" 
        element={
          <CartAwareLayout>
            <PrivacyPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/track-order" 
        element={
          <CartAwareLayout>
            <TrackOrderPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/order-confirmation/:id" 
        element={
          <CartAwareLayout>
            <OrderConfirmationPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/search" 
        element={
          <CartAwareLayout>
            <SearchPage />
          </CartAwareLayout>
        } 
      />
      <Route 
        path="/vendedor" 
        element={
          <CartAwareLayout>
            <VendedorPage />
          </CartAwareLayout>
        } 
      />
    </Routes>
  );
};

export default AppRoutes;
