import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Product } from '../types';
import { useCart } from '../contexts/CartContext';
import { useFavorites } from '../contexts/FavoritesContext';
import { Heart } from 'lucide-react';

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const navigate = useNavigate();
  const { addItem } = useCart();
  const { favorites, toggleFavorite } = useFavorites();
  
  const isOnSale = product.promotionalPeriod &&
    product.promotionalPeriod.startDate &&
    product.promotionalPeriod.endDate &&
    new Date(product.promotionalPeriod.startDate) <= new Date() &&
    new Date(product.promotionalPeriod.endDate) >= new Date() &&
    product.salePrice &&
    Number(product.salePrice) < Number(product.regularPrice);

  const isFavorite = favorites.some(fav => fav.id === product.id);

  const handleAddToCart = () => {
    // Garantir que os preços são números
    const regularPrice = typeof product.regularPrice === 'string' 
      ? parseFloat(product.regularPrice) 
      : product.regularPrice;
      
    const salePrice = product.salePrice 
      ? (typeof product.salePrice === 'string' 
          ? parseFloat(product.salePrice) 
          : product.salePrice)
      : regularPrice;

    const price = isOnSale ? salePrice : regularPrice;
    
    if (isNaN(price)) {
      console.error('Preço inválido:', { regularPrice, salePrice, price });
      return;
    }
    
    const cartItem = {
      id: `${product.id}-${product.companyId}`,
      productId: product.id,
      sellerId: product.companyId,
      price: price,
      name: product.name,
      image: product.mainImage || 'https://plataformaconcreta.pt/logos/emtuacasa/default-product.png',
      sku: product.sku || '',
      sellerName: product.companyName || '',
      sellerLogo: product.companyLogo || 'https://plataformaconcreta.pt/logos/emtuacasa/default-company.png',
      isMainSeller: true
    };
    
    addItem(cartItem, 1);
  };

  const handleBuyNow = () => {
    handleAddToCart();
    navigate('/checkout');
  };

  // Formatar preços para exibição
  const displayRegularPrice = typeof product.regularPrice === 'string' 
    ? parseFloat(product.regularPrice).toFixed(2)
    : product.regularPrice.toFixed(2);

  const displaySalePrice = product.salePrice
    ? (typeof product.salePrice === 'string'
        ? parseFloat(product.salePrice).toFixed(2)
        : product.salePrice.toFixed(2))
    : displayRegularPrice;

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <Link to={`/product/${product.id}`} className="block">
        <div className="relative mb-4">
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={product.mainImage}
              alt={product.name}
              className="w-full h-full object-contain"
              loading="lazy"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = 'https://plataformaconcreta.pt/logos/emtuacasa/default-product.png';
              }}
            />
          </div>
          {isOnSale && (
            <div className="absolute top-2 left-2">
              <span className="bg-red-500 text-white px-2 py-1 rounded-md text-sm">
                Poupe €{(Number(displayRegularPrice) - Number(displaySalePrice)).toFixed(2)}
              </span>
            </div>
          )}
          <button 
            onClick={(e) => {
              e.preventDefault();
              toggleFavorite(product);
            }}
            className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors"
          >
            <Heart 
              className={`w-5 h-5 ${isFavorite ? 'fill-red-500 text-red-500' : 'text-gray-400'}`}
            />
          </button>
        </div>
      </Link>

      <div className="space-y-2">
        {isOnSale && product.promotionalPeriod && (
          <div className="text-xs text-gray-500">
            Promoção válida de {new Date(product.promotionalPeriod.startDate).toLocaleDateString('pt-PT')} 
            até {new Date(product.promotionalPeriod.endDate).toLocaleDateString('pt-PT')}
          </div>
        )}
        <p className="text-sm text-gray-500">
          {typeof product.otherSellers === 'number' ? (
            (product.otherSellers + 1) === 1 ? 
              '1 vendedor' : 
              `${product.otherSellers + 1} vendedores`
          ) : (
            '1 vendedor'
          )}
        </p>

        <Link to={`/product/${product.id}`}>
          <h3 className="font-semibold text-lg hover:text-primary transition-colors">
            {product.name}
          </h3>
        </Link>

        <div className="flex justify-between items-center mt-4">
          <div className="space-y-1">
            {isOnSale ? (
              <>
                <p className="text-lg font-semibold text-primary">€{displaySalePrice}</p>
                <p className="text-sm text-gray-500 line-through">€{displayRegularPrice}</p>
              </>
            ) : (
              <p className="text-lg font-semibold text-primary">€{displayRegularPrice}</p>
            )}
          </div>
          <div className="space-x-2">
            <button
              onClick={handleAddToCart}
              className="bg-primary text-white px-3 py-1 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              Adicionar
            </button>
            <button
              onClick={handleBuyNow}
              className="bg-secondary text-white px-3 py-1 rounded-md hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
            >
              Comprar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;