import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

export interface ShippingAddress {
  id: string;
  userId: string;
  name: string;
  street: string;
  number: string;
  complement?: string;
  city: string;
  district: string;
  postalCode: string;
  country: string;
  email: string;
  phone: string;
  taxNumber?: string;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
}

export const useShippingAddresses = (userId: string | undefined) => {
  const [addresses, setAddresses] = useState<ShippingAddress[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) {
      setAddresses([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    const addressesRef = collection(db, 'shipping_addresses');
    const q = query(addressesRef, where('userId', '==', userId));

    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        try {
          const addressesData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as ShippingAddress[];

          // Sort addresses: default first, then by update date
          const sortedAddresses = addressesData.sort((a, b) => {
            if (a.isDefault && !b.isDefault) return -1;
            if (!a.isDefault && b.isDefault) return 1;
            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
          });

          setAddresses(sortedAddresses);
          setError(null);
        } catch (err) {
          console.error('Erro ao processar moradas:', err);
          setError('Erro ao processar moradas');
        } finally {
          setLoading(false);
        }
      },
      (err) => {
        console.error('Erro ao observar moradas:', err);
        setError('Erro ao carregar moradas');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  const addAddress = async (addressData: Omit<ShippingAddress, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) => {
    if (!userId) throw new Error('Utilizador não autenticado');

    try {
      const addressesRef = collection(db, 'shipping_addresses');

      // If it's the first address or marked as default
      if (addressData.isDefault || addresses.length === 0) {
        // Remove default from other addresses
        const defaultAddresses = addresses.filter(addr => addr.isDefault);
        for (const addr of defaultAddresses) {
          await updateDoc(doc(db, 'shipping_addresses', addr.id), {
            isDefault: false,
            updatedAt: new Date().toISOString()
          });
        }
      }

      const now = new Date().toISOString();
      const docRef = await addDoc(addressesRef, {
        ...addressData,
        userId,
        isDefault: addressData.isDefault || addresses.length === 0,
        country: addressData.country || 'Portugal',
        createdAt: now,
        updatedAt: now
      });

      return { id: docRef.id, ...addressData };
    } catch (err) {
      console.error('Erro ao adicionar morada:', err);
      throw new Error('Erro ao adicionar morada');
    }
  };

  const updateAddress = async (id: string, data: Partial<ShippingAddress>) => {
    if (!userId) throw new Error('Utilizador não autenticado');

    try {
      const addressRef = doc(db, 'shipping_addresses', id);

      if (data.isDefault) {
        // Remove default from other addresses
        const defaultAddresses = addresses.filter(addr => addr.id !== id && addr.isDefault);
        for (const addr of defaultAddresses) {
          await updateDoc(doc(db, 'shipping_addresses', addr.id), {
            isDefault: false,
            updatedAt: new Date().toISOString()
          });
        }
      }

      await updateDoc(addressRef, {
        ...data,
        updatedAt: new Date().toISOString()
      });
    } catch (err) {
      console.error('Erro ao atualizar morada:', err);
      throw new Error('Erro ao atualizar morada');
    }
  };

  const deleteAddress = async (id: string) => {
    if (!userId) throw new Error('Utilizador não autenticado');

    try {
      const addressToDelete = addresses.find(addr => addr.id === id);
      await deleteDoc(doc(db, 'shipping_addresses', id));

      // If deleted address was default and there are other addresses
      if (addressToDelete?.isDefault && addresses.length > 1) {
        const nextAddress = addresses.find(addr => addr.id !== id);
        if (nextAddress) {
          await updateDoc(doc(db, 'shipping_addresses', nextAddress.id), {
            isDefault: true,
            updatedAt: new Date().toISOString()
          });
        }
      }
    } catch (err) {
      console.error('Erro ao eliminar morada:', err);
      throw new Error('Erro ao eliminar morada');
    }
  };

  return {
    addresses,
    loading,
    error,
    addAddress,
    updateAddress,
    deleteAddress
  };
};