import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';
import { useWallet } from '../hooks/useWallet';
import { useShippingAddresses, ShippingAddress } from '../hooks/useShippingAddresses';
import { useShippingCosts } from '../hooks/useShippingCosts';
import { createOrder } from '../lib/firebase';
import { loginWithEmail } from '../lib/firebase';
import { generateMultibancoPayment } from '../lib/firebase';
import { generateMBWayPayment } from '../lib/firebase';
import { addDoc, collection, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Loader, CreditCard, Landmark, Check } from 'lucide-react';
import AddressForm from '../components/AddressForm';
import AddressSelector from '../components/AddressSelector';
import MBWayPayment from '../components/MBWayPayment';
import MultibancoPayment from '../components/MultibancoPayment';
import RegisterForm from '../components/auth/RegisterForm';
import OrderSummary from '../components/OrderSummary';

const CheckoutPage = () => {
  const generateOrderNumber = () => {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 1000);
    return `ORD-${timestamp}-${random}`;
  };

  const navigate = useNavigate();
  const { user, signIn } = useAuth();
  const { items, clearCart, getTotal } = useCart();
  const { wallet } = useWallet(user?.uid);
  const { addresses, loading: addressesLoading, addAddress, updateAddress, deleteAddress } = useShippingAddresses(user?.uid);
  const { costs } = useShippingCosts();
  
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<'mbway' | 'multibanco' | null>(null);
  const [mbwayPhone, setMbwayPhone] = useState<string | null>(null);
  const [shippingCost, setShippingCost] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [editingAddress, setEditingAddress] = useState<ShippingAddress | null>(null);
  const [useWalletBalance, setUseWalletBalance] = useState(true);

  // Calculate cart total
  const calculateCartTotal = () => {
    return items.reduce((total, item) => {
      const price = Number(item.product.salePrice || item.product.price) || 0;
      return total + (price * item.quantity);
    }, 0);
  };

  // Calculate values
  const cartSubtotal = calculateCartTotal();
  const shippingAmount = Number(shippingCost) || 0;
  const orderTotal = cartSubtotal + shippingAmount;
  const walletBalance = Number(wallet?.balance) || 0;
  
  // Calculate wallet usage
  const walletAmountToUse = useWalletBalance ? Math.min(walletBalance, orderTotal) : 0;
  const remainingTotal = Math.max(0, orderTotal - walletAmountToUse);

  // For display in the order summary
  const displaySubtotal = cartSubtotal;
  const displayShipping = shippingAmount;
  const displayTotal = orderTotal;
  const displayWalletUsed = walletAmountToUse;
  const displayRemainingTotal = remainingTotal;

  // Check if cart is empty only on initial mount
  useEffect(() => {
    const cartItems = localStorage.getItem('cartItems');
    if (!cartItems || JSON.parse(cartItems).length === 0) {
      navigate('/cart');
    }
  }, []); // Empty dependency array means this only runs once on mount

  // Save cart items to localStorage whenever they change
  useEffect(() => {
    if (items && items.length > 0) {
      localStorage.setItem('cartItems', JSON.stringify(items));
    }
  }, [items]);

  const handleAddressSelect = (address: ShippingAddress) => {
    setSelectedAddressId(address.id);
    
    // Calcular custo de envio baseado no distrito
    if (address.district) {
      const normalizedDistrict = address.district.toLowerCase().trim();
      
      // Verificar se é Madeira
      if (normalizedDistrict.includes('madeira') || normalizedDistrict === 'região autónoma da madeira') {
        setShippingCost(15.70);
      } else {
        // Para outros distritos
        const cost = costs[normalizedDistrict] || 3.99;
        setShippingCost(cost);
      }
    } else {
      setShippingCost(3.99); // Custo padrão
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    try {
      await loginWithEmail(loginEmail, loginPassword);
    } catch (err) {
      if (err instanceof Error) {
        if (err.message.includes('Vendedores não podem usar')) {
          setError('Vendedores não podem usar o site da LOGNOW. Para efectuar compras use o "Vendas Privadas" no painel de controlo.');
        } else {
          setError('Email ou palavra-passe incorretos. Por favor, tente novamente.');
        }
      } else {
        setError('Ocorreu um erro ao iniciar sessão. Por favor, tente novamente.');
      }
    }
  };

  const handlePlaceOrder = async () => {
    if (!selectedAddressId) {
      setError('Por favor, selecione um endereço de entrega');
      return;
    }

    if (remainingTotal > 0 && !paymentMethod) {
      setError('Por favor, selecione um método de pagamento');
      return;
    }

    try {
      if (remainingTotal > 0) {
        if (paymentMethod === 'multibanco') {
          await handleMultibancoPayment();
        } else if (paymentMethod === 'mbway' && mbwayPhone) {
          await handleMBWayPayment();
        }
      } else {
        // Se o saldo da wallet cobrir todo o valor, processar diretamente
        await handleWalletPayment();
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Erro ao processar o pedido');
    }
  };

  const handleMultibancoPayment = async () => {
    try {
      setProcessing(true);
      setError(null);
      
      // Garantir que todos os valores sejam números
      const finalTotal = Number(cartSubtotal) + Number(shippingAmount);
      const finalSubtotal = Number(cartSubtotal);
      const finalShippingCost = Number(shippingAmount);

      // Criar o pedido
      const selectedAddress = addresses.find(addr => addr.id === selectedAddressId);
      if (!selectedAddress) {
        throw new Error('Morada não encontrada');
      }

      // Garantir que todos os campos do endereço estejam presentes
      const shippingAddress = {
        name: selectedAddress.name || user.displayName || '',
        street: selectedAddress.street || '',
        number: selectedAddress.number || '',
        complement: selectedAddress.complement || '',
        city: selectedAddress.city || '',
        district: selectedAddress.district || '',
        postalCode: selectedAddress.postalCode || '',
        country: selectedAddress.country || 'Portugal',
        email: user.email || '',
        phone: selectedAddress.phone || '',
        taxNumber: selectedAddress.taxNumber || ''
      };

      // Buscar os produtos para obter os companyIds corretos
      const itemsWithCompanyId = await Promise.all(items.map(async (item) => {
        const productRef = doc(db, 'products', item.product.id.split('-')[0]); // Remove o sufixo duplicado
        const productSnap = await getDoc(productRef);
        const productData = productSnap.exists() ? productSnap.data() : null;
        
        return {
          ...item,
          companyId: productData?.companyId || ''
        };
      }));

      // Garantir que todos os itens tenham os campos necessários
      const orderItems = itemsWithCompanyId.map(item => ({
        productId: item.product.id || '',
        quantity: item.quantity || 0,
        price: (item.product.salePrice || item.product.price) || 0,
        companyId: item.companyId || '',
        name: item.product.name || '',
        image: item.product.image || ''
      }));

      const orderData = {
        userId: user.uid,
        buyerId: user.uid,
        items: orderItems,
        shippingAddress,
        shippingCost: finalShippingCost,
        paymentMethod: 'multibanco',
        subtotal: finalSubtotal,
        total: finalTotal,
        isOnlineSale: true,
        status: 'awaiting_payment'
      };

      const orderId = await createOrder(orderData);

      // Limpar o carrinho
      await clearCart();
      
      // Redirecionar para a página de confirmação
      navigate(`/order-confirmation/${orderId}`);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Erro ao processar pagamento Multibanco');
    } finally {
      setProcessing(false);
    }
  };

  const handleMBWayPayment = async (phoneNumber?: string) => {
    try {
      setProcessing(true);
      setError(null);
      
      // Use the provided phone number or the state value
      const phone = phoneNumber || mbwayPhone;
      
      if (!phone) {
        throw new Error('Por favor, insira o número de telemóvel MBWay');
      }

      if (!selectedAddressId) {
        throw new Error('Por favor, selecione um endereço de entrega');
      }

      // Garantir que todos os valores sejam números
      const finalTotal = Number(cartSubtotal) + Number(shippingAmount);
      const finalSubtotal = Number(cartSubtotal);
      const finalShippingCost = Number(shippingAmount);

      // Criar o pedido
      const selectedAddress = addresses.find(addr => addr.id === selectedAddressId);
      if (!selectedAddress) {
        throw new Error('Morada não encontrada');
      }

      // Garantir que todos os campos do endereço estejam presentes
      const shippingAddress = {
        name: selectedAddress.name || user.displayName || '',
        street: selectedAddress.street || '',
        number: selectedAddress.number || '',
        complement: selectedAddress.complement || '',
        city: selectedAddress.city || '',
        district: selectedAddress.district || '',
        postalCode: selectedAddress.postalCode || '',
        country: selectedAddress.country || 'Portugal',
        email: user.email || '',
        phone: selectedAddress.phone || '',
        taxNumber: selectedAddress.taxNumber || ''
      };

      // Buscar os produtos para obter os companyIds corretos
      const itemsWithCompanyId = await Promise.all(items.map(async (item) => {
        const productRef = doc(db, 'products', item.product.id.split('-')[0]); // Remove o sufixo duplicado
        const productSnap = await getDoc(productRef);
        const productData = productSnap.exists() ? productSnap.data() : null;
        
        return {
          ...item,
          companyId: productData?.companyId || ''
        };
      }));

      // Garantir que todos os itens tenham os campos necessários
      const orderItems = itemsWithCompanyId.map(item => ({
        productId: item.product.id || '',
        quantity: item.quantity || 0,
        price: (item.product.salePrice || item.product.price) || 0,
        companyId: item.companyId || '',
        name: item.product.name || '',
        image: item.product.image || ''
      }));

      const orderData = {
        userId: user.uid,
        buyerId: user.uid,
        items: orderItems,
        shippingAddress,
        shippingCost: finalShippingCost,
        paymentMethod: 'mbway',
        subtotal: finalSubtotal,
        total: finalTotal,
        isOnlineSale: true,
        status: 'awaiting_payment',
        mbwayPhone: phone
      };

      const orderId = await createOrder(orderData);

      // Limpar o carrinho
      await clearCart();
      
      // Redirecionar para a página de confirmação
      navigate(`/order-confirmation/${orderId}`);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Erro ao processar pagamento MBWay');
    } finally {
      setProcessing(false);
    }
  };

  const handleWalletPayment = async () => {
    try {
      if (!user || !user.uid) {
        throw new Error('Usuário não está autenticado');
      }

      setProcessing(true);
      setError(null);
      
      // Garantir que todos os valores sejam números
      const finalTotal = Number(cartSubtotal) + Number(shippingAmount);
      const finalSubtotal = Number(cartSubtotal);
      const finalShippingCost = Number(shippingAmount);
      const walletAmount = Math.min(walletBalance, finalTotal);

      // Criar o pedido
      const selectedAddress = addresses.find(addr => addr.id === selectedAddressId);
      if (!selectedAddress) {
        throw new Error('Morada não encontrada');
      }

      // Garantir que todos os campos do endereço estejam presentes
      const shippingAddress = {
        name: selectedAddress.name || user.displayName || '',
        street: selectedAddress.street || '',
        number: selectedAddress.number || '',
        complement: selectedAddress.complement || '',
        city: selectedAddress.city || '',
        district: selectedAddress.district || '',
        postalCode: selectedAddress.postalCode || '',
        country: selectedAddress.country || 'Portugal',
        email: user.email || '',
        phone: selectedAddress.phone || '',
        taxNumber: selectedAddress.taxNumber || ''
      };

      // Buscar os produtos para obter os companyIds corretos
      const itemsWithCompanyId = await Promise.all(items.map(async (item) => {
        const productRef = doc(db, 'products', item.product.id.split('-')[0]); // Remove o sufixo duplicado
        const productSnap = await getDoc(productRef);
        const productData = productSnap.exists() ? productSnap.data() : null;
        
        return {
          ...item,
          companyId: productData?.companyId || ''
        };
      }));

      // Garantir que todos os itens tenham os campos necessários
      const orderItems = itemsWithCompanyId.map(item => ({
        productId: item.product.id || '',
        quantity: item.quantity || 0,
        price: (item.product.salePrice || item.product.price) || 0,
        companyId: item.companyId || '',
        name: item.product.name || '',
        image: item.product.image || ''
      }));

      const orderData = {
        userId: user.uid,
        buyerId: user.uid,
        items: orderItems,
        shippingAddress,
        shippingCost: finalShippingCost,
        paymentMethod: 'wallet',
        subtotal: finalSubtotal,
        total: finalTotal,
        walletAmount: walletAmount,
        status: 'processing',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        orderNumber: generateOrderNumber(),
        isOnlineSale: true
      };

      // Criar o pedido e atualizar a wallet
      const orderRef = await addDoc(collection(db, 'orders'), orderData);

      // Distribuir os pagamentos para os vendedores
      await distributeWalletPayment(orderItems, orderData.orderNumber);

      // Atualizar o saldo da wallet do comprador
      if (!user?.uid) {
        throw new Error('ID do usuário não encontrado');
      }
      const walletsRef = collection(db, 'wallets');
      const buyerWalletRef = doc(walletsRef, user.uid);
      await updateDoc(buyerWalletRef, {
        balance: increment(-walletAmount),
        updatedAt: new Date().toISOString()
      });

      // Registrar a transação na wallet
      await addDoc(collection(db, 'walletTransactions'), {
        userId: user.uid,
        orderId: orderData.orderNumber,
        amount: -walletAmount,
        description: `Pagamento da encomenda #${orderData.orderNumber}`,
        createdAt: new Date().toISOString(),
        status: 'completed',
        type: 'order_payment'
      });

      // Limpar o carrinho e redirecionar
      clearCart();
      navigate(`/order-confirmation/${orderRef.id}`);
    } catch (error) {
      console.error('Error processing wallet payment:', error);
      throw new Error('Erro ao processar o pagamento com a wallet');
    } finally {
      setProcessing(false);
    }
  };

  const PLATFORM_FEE_PERCENTAGE = 10; // 10% de comissão para a plataforma
  const PLATFORM_WALLET_ID = 'emtuacasa'; // ID da wallet da plataforma

  const distributeWalletPayment = async (orderItems: any[], orderNumber: string) => {
    const failedItems: any[] = [];

    for (const item of orderItems) {
      try {
        console.log('Processando item:', item);

        // Verificar se temos o productId
        if (!item.productId) {
          console.error('Item sem productId:', item);
          failedItems.push({
            ...item,
            error: 'Item sem productId'
          });
          continue;
        }

        // Buscar o produto para obter o companyId (ID da wallet do vendedor)
        const productRef = doc(db, 'products', item.productId.split('-')[0]); // Remove o sufixo duplicado
        const productSnap = await getDoc(productRef);
        
        if (!productSnap.exists()) {
          console.error('Produto não encontrado:', item.productId);
          failedItems.push({
            ...item,
            error: 'Produto não encontrado'
          });
          continue;
        }

        const productData = productSnap.data();
        const vendorId = productData.companyId;

        if (!vendorId) {
          console.error('Produto sem vendedor:', item.productId);
          failedItems.push({
            ...item,
            error: 'Produto sem vendedor'
          });
          continue;
        }

        const totalAmount = item.price * item.quantity;
        const platformFee = (totalAmount * PLATFORM_FEE_PERCENTAGE) / 100;
        const vendorAmount = totalAmount - platformFee;

        console.log('Valores calculados:', {
          produto: item.name,
          precoUnitario: item.price,
          quantidade: item.quantity,
          valorTotal: totalAmount,
          comissao: platformFee,
          valorVendedor: vendorAmount,
          vendorId: vendorId
        });

        // Atualizar a wallet do vendedor com o valor menos a comissão
        const walletsRef = collection(db, 'wallets');
        const vendorWalletRef = doc(walletsRef, vendorId);
        await updateDoc(vendorWalletRef, {
          balance: increment(vendorAmount),
          updatedAt: new Date().toISOString()
        });

        // Registrar a transação para o vendedor
        const vendorTransactionRef = collection(db, 'walletTransactions');
        await addDoc(vendorTransactionRef, {
          userId: vendorId,
          amount: vendorAmount,
          type: 'sale_payment',
          description: `Pagamento da venda #${orderNumber} (${item.name}) - Valor após comissão`,
          createdAt: new Date().toISOString(),
          orderId: orderNumber,
          productId: item.productId,
          originalAmount: totalAmount,
          platformFee: platformFee,
          productDetails: {
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            sku: item.sku || ''
          }
        });

        // Registrar a comissão para a plataforma
        const platformWalletRef = doc(walletsRef, PLATFORM_WALLET_ID);
        await updateDoc(platformWalletRef, {
          balance: increment(platformFee),
          updatedAt: new Date().toISOString()
        });

        // Registrar a transação da comissão
        await addDoc(collection(db, 'walletTransactions'), {
          userId: PLATFORM_WALLET_ID,
          amount: platformFee,
          type: 'commission',
          description: `Comissão da venda #${orderNumber} (${item.name})`,
          createdAt: new Date().toISOString(),
          orderId: orderNumber,
          productId: item.productId,
          vendorId: vendorId,
          isOnlineSale: true,
          productDetails: {
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            sku: item.sku || ''
          }
        });

      } catch (error) {
        console.error('Erro ao processar pagamento para o item:', item, error);
        failedItems.push({
          ...item,
          error: error instanceof Error ? error.message : 'Erro desconhecido'
        });
      }
    }

    if (failedItems.length > 0) {
      console.error('Alguns itens falharam durante o processamento:', failedItems);
      throw new Error(`Falha ao processar ${failedItems.length} item(s). Verifique o console para mais detalhes.`);
    }
  };

  const handleEditAddress = (address: ShippingAddress) => {
    setEditingAddress(address);
    setShowAddressForm(true);
  };

  const handleCloseForm = () => {
    setShowAddressForm(false);
    setEditingAddress(null);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-secondary mb-8">Checkout</h1>
        
        {/* Main Grid Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Left Column - Forms */}
          <div className="lg:col-span-8 space-y-6">
            {/* Account Section */}
            <div className="bg-white shadow-md rounded-lg p-4 sm:p-6">
              <h2 className="text-lg font-semibold text-secondary mb-4">1. Conta</h2>
              {user ? (
                <p className="text-gray-600">Logado como {user.email}</p>
              ) : (
                <div className="space-y-4">
                  <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                    <div>
                      <h3 className="font-medium text-gray-700">Já tem conta?</h3>
                      <p className="text-sm text-gray-500">Faça login para continuar</p>
                    </div>
                    <button
                      onClick={() => setShowRegisterForm(false)}
                      className="w-full sm:w-auto px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark"
                    >
                      Login
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Address Section */}
            <div className="bg-white shadow-md rounded-lg p-4 sm:p-6">
              <h2 className="text-lg font-semibold text-secondary mb-4">2. Endereço de Entrega</h2>
              <div className="space-y-4">
                {addresses.map((address) => (
                  <div
                    key={address.id}
                    className={`relative p-4 border rounded-lg cursor-pointer transition-colors ${
                      selectedAddressId === address.id
                        ? 'border-primary bg-primary/5'
                        : 'border-gray-200 hover:border-primary'
                    }`}
                    onClick={() => handleAddressSelect(address)}
                  >
                    <div className="flex flex-col sm:flex-row justify-between gap-4">
                      <div>
                        <p className="font-medium text-gray-800">{address.name}</p>
                        <p className="text-gray-600">{address.street}, {address.number}</p>
                        <p className="text-gray-600">{address.postalCode} {address.city}</p>
                        <p className="text-gray-600">{address.district}</p>
                      </div>
                      <div className="flex justify-end sm:justify-start gap-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingAddress(address);
                            setShowAddressForm(true);
                          }}
                          className="p-2 text-gray-500 hover:text-primary"
                        >
                          <span className="sr-only">Editar</span>
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                          </svg>
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteAddress(address.id);
                          }}
                          className="p-2 text-gray-500 hover:text-red-500"
                        >
                          <span className="sr-only">Remover</span>
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                
                <button
                  onClick={() => {
                    setEditingAddress(null);
                    setShowAddressForm(true);
                  }}
                  className="w-full py-3 px-4 border-2 border-dashed border-gray-300 text-gray-600 rounded-lg hover:border-primary hover:text-primary transition-colors"
                >
                  + Adicionar nova morada
                </button>
              </div>
            </div>

            {/* Payment Section */}
            <div className="bg-white shadow-md rounded-lg p-4 sm:p-6">
              <h2 className="text-lg font-semibold text-secondary mb-4">3. Método de Pagamento</h2>
              {user && selectedAddressId && (
                <div className="space-y-6">
                  {/* Wallet Section */}
                  {walletBalance > 0 && (
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-medium text-gray-700">Saldo na carteira</p>
                          <p className="text-sm text-gray-500">Use seu saldo disponível</p>
                        </div>
                        <p className="font-medium text-primary">{walletBalance.toFixed(2)}</p>
                      </div>
                      
                      <div className="flex items-center gap-4">
                        <input
                          type="checkbox"
                          id="useWallet"
                          checked={useWalletBalance}
                          onChange={(e) => setUseWalletBalance(e.target.checked)}
                          className="h-4 w-4 text-primary border-gray-300 rounded focus:ring-primary"
                        />
                        <label htmlFor="useWallet" className="text-sm text-gray-700">
                          Usar saldo da carteira {useWalletBalance && `(${displayWalletUsed.toFixed(2)})`}
                        </label>
                      </div>
                    </div>
                  )}

                  {/* Payment Methods */}
                  {remainingTotal > 0 && (
                    <div className="space-y-4">
                      <p className="font-medium text-gray-700">Selecione o método de pagamento</p>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <button
                          onClick={() => setPaymentMethod('mbway')}
                          className={`p-4 border rounded-lg flex items-center justify-center hover:border-primary transition-colors ${
                            paymentMethod === 'mbway' ? 'border-primary bg-primary/5' : 'border-gray-200'
                          }`}
                        >
                          <div className="flex flex-col items-center space-y-2">
                            <img 
                              src="https://endpoint-mbway.azureedge.net/wp-content/uploads/2020/07/Logo_MBWay@2x.png" 
                              alt="MB WAY" 
                              className="h-8 w-auto object-contain"
                            />
                          </div>
                        </button>

                        <button
                          onClick={() => setPaymentMethod('multibanco')}
                          className={`p-4 border rounded-lg flex items-center justify-center hover:border-primary transition-colors ${
                            paymentMethod === 'multibanco' ? 'border-primary bg-primary/5' : 'border-gray-200'
                          }`}
                        >
                          <div className="flex flex-col items-center space-y-2">
                            <img 
                              src="https://www.eupago.pt/u/b/be1d5d6277447fa4630089be1fff6fa2.jpg" 
                              alt="Multibanco" 
                              className="h-8 w-auto object-contain"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Place Order Button */}
                  <button
                    onClick={handlePlaceOrder}
                    disabled={processing || !selectedAddressId || (remainingTotal > 0 && !paymentMethod)}
                    className={`w-full py-3 px-4 rounded-lg text-white font-medium transition-colors ${
                      (selectedAddressId && (remainingTotal === 0 || paymentMethod)) 
                        ? 'bg-primary hover:bg-primary-dark'
                        : 'bg-gray-300 cursor-not-allowed'
                    }`}
                  >
                    {processing ? (
                      <span className="flex items-center justify-center gap-2">
                        <Loader className="animate-spin" size={20} />
                        A processar...
                      </span>
                    ) : (
                      `Finalizar Compra (€${displayRemainingTotal.toFixed(2)})`
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Order Summary */}
          <div className="lg:col-span-4">
            <div className="bg-white shadow-md rounded-lg p-4 sm:p-6 sticky top-8">
              <h2 className="text-lg font-semibold text-secondary mb-4">Resumo do Pedido</h2>
              
              <div className="space-y-4">
                {/* Cart Items */}
                <div className="space-y-3">
                  {items.map((item) => (
                    <div key={item.product.id} className="flex items-center gap-3">
                      <div className="relative w-16 h-16 rounded-lg overflow-hidden bg-gray-100">
                        <img
                          src={item.product.image}
                          alt={item.product.name}
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="font-medium text-gray-800 truncate">{item.product.name}</p>
                        <p className="text-sm text-gray-500">Qtd: {item.quantity}</p>
                        <p className="text-sm font-medium text-primary">{(item.product.salePrice || item.product.price) * item.quantity}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Totals */}
                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between text-sm text-gray-600">
                    <span>Subtotal</span>
                    <span>{displaySubtotal.toFixed(2)}</span>
                  </div>
                  
                  {useWalletBalance && walletBalance > 0 && (
                    <div className="flex justify-between text-sm text-gray-600">
                      <span>Carteira</span>
                      <span>-{displayWalletUsed.toFixed(2)}</span>
                    </div>
                  )}
                  
                  <div className="flex justify-between font-medium text-gray-900">
                    <span>Total</span>
                    <span>{displayTotal.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;