import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  collection, 
  query, 
  where, 
  getDocs,
  getDoc,
  doc,
  DocumentReference,
  DocumentData
} from 'firebase/firestore';
import { db } from '../firebase';
import { useProduct } from '../hooks/useProduct';
import { useCompany } from '../hooks/useCompany';
import { useAuth } from '../hooks/useAuth';
import { useCart } from '../contexts/CartContext';
import { useFavorites } from '../contexts/FavoritesContext';
import { 
  Loader, 
  Star,
  History,
  Bell,
  Heart,
  Truck,
  Shield,
  RefreshCw,
  Headphones,
  Box
} from 'lucide-react';
import ProductSellers from '../components/ProductSellers';
import SEO from '../components/SEO';

interface Seller {
  id: string;
  name: string;
  logo?: string;
  regularPrice: number;
  salePrice?: number;
  sponsored: boolean;
  sponsorshipStartDate?: string;
  sponsorshipEndDate?: string;
  stock: number;
  stockStatus: string;
  rating?: number;
  fulfillment?: boolean;
  promotionalPeriod?: {
    startDate: string;
    endDate: string;
  }
}

interface Company {
  name: string;
  logo?: string;
  id?: string;
}

interface ProductData {
  id: string;
  ean: string;
  prices: { [key: string]: number };
  marketplacePrices: { [key: string]: number };
  regularPrice: number;
  salePrice: number;
  sponsored: boolean;
  sponsorshipStartDate?: string;
  sponsorshipEndDate?: string;
  stock: number;
  stockStatus: string;
  companyId: string;
  company?: {
    name: string;
    logo?: string;
  }
  name: string;
  logo?: string;
  baselinker_id?: string;
  category?: string;
  sku?: string;
  fulfillment?: boolean;
}

const ProductPage = () => {
  const { id } = useParams();
  const { product, loading, error } = useProduct(id);
  const { user } = useAuth();
  const { addItem } = useCart();
  const { favorites, toggleFavorite } = useFavorites();
  const [currentImage, setCurrentImage] = useState('');
  const [showZoomedImage, setShowZoomedImage] = useState(false);
  const [mainSeller, setMainSeller] = useState<Seller | null>(null);
  const [otherSellers, setOtherSellers] = useState<Seller[]>([]);
  const [isLoadingSellers, setIsLoadingSellers] = useState(true);
  const [showCartAnimation, setShowCartAnimation] = useState(false);

  const isFavorite = product ? favorites.some(fav => fav.id === product.id) : false;

  const handleAddToCart = (selectedSeller: Seller) => {
    const price = selectedSeller.salePrice || selectedSeller.regularPrice;
    
    if (typeof price !== 'number' || isNaN(price)) {
      return;
    }

    const cartItem = {
      id: `${product.id}-${selectedSeller.id}`,
      productId: product.id,
      sellerId: selectedSeller.id,
      price: price,
      name: product.name || '',
      image: product.mainImage || 'https://plataformaconcreta.pt/logos/lognow/default-product.png',
      sellerName: selectedSeller.name,
      sellerLogo: selectedSeller.logo || 'https://plataformaconcreta.pt/logos/lognow/default-company.png',
      sku: product.sku || '',
      isMainSeller: selectedSeller.id === mainSeller?.id
    };
    
    addItem(cartItem, 1);
    setShowCartAnimation(true);
    setTimeout(() => setShowCartAnimation(false), 1000);
  };

  useEffect(() => {
    if (product?.mainImage) {
      setCurrentImage(product.mainImage);
    }
  }, [product]);

  useEffect(() => {
    const fetchSellers = async () => {
      if (!product?.ean) {
        return;
      }

      try {
        setIsLoadingSellers(true);
        
        const productsRef = collection(db, 'products');
        const productsQuery = query(
          productsRef,
          where('ean', '==', product.ean)
        );
        
        const productsSnapshot = await getDocs(productsQuery);
        const allSellers: Seller[] = [];
        const currentDate = new Date();

        for (const productDoc of productsSnapshot.docs) {
          const data = productDoc.data() as ProductData;
          
          // Skip if no companyId or no stock
          if (!data.companyId || data.stock <= 0 || data.stockStatus !== 'instock') {
            continue;
          }
          
          try {
            const companyRef = doc(db, 'companies', data.companyId);
            const companyDoc = await getDoc(companyRef);
            
            if (!companyDoc.exists()) {
              continue;
            }
            
            const companyData = companyDoc.data();
            const priceMap = data.marketplacePrices || data.prices || {};
            const priceValues = Object.values(priceMap)
              .filter((p: any) => typeof p === 'number' && p > 0);
            
            if (priceValues.length === 0) {
              continue;
            }

            const currentPrice = Math.min(...priceValues);
            
            // Check if sponsorship is active
            const isSponsored = data.sponsored && 
              data.sponsorshipStartDate && 
              data.sponsorshipEndDate &&
              new Date(data.sponsorshipStartDate) <= currentDate &&
              new Date(data.sponsorshipEndDate) >= currentDate;

            const seller: Seller = {
              id: productDoc.id,
              name: companyData.commercialName || '',
              logo: companyData.logo || companyData.logoBase64 || '',
              regularPrice: data.regularPrice || currentPrice,
              salePrice: data.salePrice || currentPrice,
              sponsored: isSponsored,
              stock: data.stock,
              stockStatus: data.stockStatus,
              rating: data.rating || 0,
              fulfillment: data.baselinker_id ? true : false,
              promotionalPeriod: data.promotionalPeriod
            };

            if (seller.name && seller.regularPrice > 0) {
              allSellers.push(seller);
            }
          } catch (error) {
            continue;
          }
        }

        // Sort sellers based on the specified rules
        const sortedSellers = allSellers.sort((a, b) => {
          // First priority: Sponsored with best price
          if (a.sponsored && !b.sponsored) return -1;
          if (!a.sponsored && b.sponsored) return 1;

          // Second priority: Best price
          const aPrice = a.salePrice || a.regularPrice;
          const bPrice = b.salePrice || b.regularPrice;
          if (aPrice !== bPrice) return aPrice - bPrice;

          // Third priority: Rating
          if (a.rating !== b.rating) return b.rating - a.rating;

          // Fourth priority: Stock quantity
          return b.stock - a.stock;
        });

        if (sortedSellers.length > 0) {
          setMainSeller(sortedSellers[0]);
          setOtherSellers(sortedSellers.slice(1));
        }

      } catch (err) {
        // Handle error silently
      } finally {
        setIsLoadingSellers(false);
      }
    };

    fetchSellers();
  }, [product]);

  const handleZoom = () => {
    setShowZoomedImage(!showZoomedImage);
  };

  const isValidDate = (dateString: string) => {
    if (!dateString || dateString === '') return false;
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
  };

  const formatDate = (dateString: string) => {
    if (!isValidDate(dateString)) return '';
    return new Date(dateString).toLocaleDateString('pt-PT');
  };

  const isValidPromotion = (seller: Seller) => {
    return seller.promotionalPeriod &&
      isValidDate(seller.promotionalPeriod.startDate) &&
      isValidDate(seller.promotionalPeriod.endDate) &&
      seller.salePrice &&
      seller.salePrice > 0 &&
      seller.salePrice < seller.regularPrice;
  };

  const getDisplayPrice = (seller: Seller) => {
    if (isValidPromotion(seller)) {
      return seller.salePrice;
    }
    return seller.regularPrice;
  };

  if (loading || isLoadingSellers) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (error || !product) {
    return (
      <div className="flex items-center justify-center min-h-screen text-red-500">
        {error || 'Produto não encontrado'}
      </div>
    );
  }

  const baseUrl = window.location.origin;
  const productUrl = `${baseUrl}/product/${product.id}`;

  return (
    <div className="container mx-auto px-4 py-8">
      <SEO
        title={product.name}
        description={product.description}
        image={product.mainImage}
        url={productUrl}
        type="product"
        brand="EM TUA CASA®"
        availability={true}
        productId={product.id}
      />

      <div className="mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Product Images */}
          <div className="space-y-6" style={{ maxHeight: '600px' }}>
            <div className="aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden relative" style={{ maxHeight: '450px' }}>
              <img
                src={currentImage || product.mainImage || 'https://via.placeholder.com/600'}
                alt={product.name}
                className="w-full h-full object-center object-contain p-4"
              />
              <button 
                onClick={() => product && toggleFavorite(product)}
                className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors"
              >
                <Heart 
                  className={`w-6 h-6 ${isFavorite ? 'fill-red-500 text-red-500' : 'text-gray-400'}`}
                />
              </button>
              <button 
                onClick={handleZoom}
                className="absolute top-2 right-2 p-2 bg-white/80 rounded-full hover:bg-white transition-colors"
              >
                <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </button>
            </div>
            {product.galleryImages && product.galleryImages.length > 0 && (
              <div className="grid grid-cols-4 gap-4">
                <button
                  onClick={() => setCurrentImage(product.mainImage)}
                  className={`aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden ${
                    currentImage === product.mainImage ? 'ring-2 ring-primary' : ''
                  }`}
                >
                  <img
                    src={product.mainImage}
                    alt={`${product.name} - principal`}
                    className="w-full h-full object-center object-contain p-2"
                  />
                </button>
                {product.galleryImages.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentImage(image)}
                    className={`aspect-w-1 aspect-h-1 bg-white rounded-lg overflow-hidden ${
                      currentImage === image ? 'ring-2 ring-primary' : ''
                    }`}
                  >
                    <img
                      src={image}
                      alt={`${product.name} - imagem ${index + 1}`}
                      className="w-full h-full object-center object-contain p-2"
                    />
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Product Info & Main Seller */}
          <div className="space-y-6">
            {/* Breadcrumb with current category */}
            <nav className="flex items-center text-sm text-gray-500 mb-6" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-2">
                <li>
                  <a href="/" className="hover:text-secondary">Página inicial</a>
                </li>
                <span className="mx-2">•</span>
                <li>
                  <span className="text-gray-700">{product.category || 'Categoria'}</span>
                </li>
              </ol>
            </nav>

            {/* Product Title */}
            <div className="mb-6">
              <h1 className="text-2xl font-bold text-gray-900 mb-2">{product.name}</h1>
            </div>

            {/* Shipping Info with Fulfillment Badge */}
            <div className="bg-gray-50 rounded-lg p-4 mb-6">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center text-secondary">
                  <Truck className="w-5 h-5 mr-2" />
                  <span className="font-medium">Envio desde 3.99€</span>
                </div>
                {mainSeller?.fulfillment && (
                  <div className="flex items-center bg-[#C1D82F] text-white px-3 py-1 rounded-full">
                    <Box className="w-4 h-4 mr-1" />
                    <span className="text-sm font-medium">Produto Fulfillment</span>
                  </div>
                )}
              </div>
              <div className="text-sm text-gray-600">
                Encomenda até às 15h para envio hoje
              </div>
              {mainSeller?.fulfillment && (
                <div className="mt-2 text-sm text-gray-700 bg-white p-2 rounded">
                  Este produto está nos nossos armazéns e será enviado diretamente por nós, garantindo entrega rápida e segura.
                </div>
              )}
            </div>

            {/* Main Seller Section */}
            {mainSeller && (
              <div className="bg-white rounded-lg shadow-md p-6 mb-6">
                <div className="flex items-center mb-4">
                  <img
                    src={mainSeller.logo || 'https://plataformaconcreta.pt/logos/lognow/default-company.png'}
                    alt={mainSeller.name}
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <div>
                    <h3 className="font-medium text-gray-900">{mainSeller.name}</h3>
                    <div className="flex items-center">
                      <span className="text-sm text-gray-500 mr-2">Vendedor Principal</span>
                      {mainSeller.sponsored && (
                        <span className="bg-blue-100 text-blue-800 text-xs px-2 py-0.5 rounded">
                          Patrocinado
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-right">
                    <p className="text-2xl font-bold text-primary">
                      €{getDisplayPrice(mainSeller).toFixed(2)}
                    </p>
                    {isValidPromotion(mainSeller) && (
                      <>
                        <p className="text-sm text-gray-500 line-through">
                          €{mainSeller.regularPrice.toFixed(2)}
                        </p>
                        <div className="text-sm text-gray-600 mt-2">
                          <p>Promoção válida de {formatDate(mainSeller.promotionalPeriod.startDate)} até {formatDate(mainSeller.promotionalPeriod.endDate)}</p>
                        </div>
                      </>
                    )}
                  </div>
                  <button
                    onClick={() => handleAddToCart(mainSeller)}
                    className="bg-[#C1D82F] hover:bg-[#a8bc29] text-white font-semibold py-3 px-8 rounded-lg text-lg shadow-sm transition-all duration-200"
                  >
                    Comprar agora
                  </button>
                </div>
              </div>
            )}

            {/* Trust Badges */}
            <div className="grid grid-cols-3 gap-6 mt-8 pt-6 border-t">
              <div className="flex flex-col items-center text-center p-4 bg-gray-50 rounded-lg">
                <Shield className="w-8 h-8 text-secondary mb-3" />
                <div>
                  <div className="font-medium mb-1">Garantia de Satisfação</div>
                  <div className="text-sm text-gray-600">Garantimos a qualidade dos nossos produtos e serviços.</div>
                </div>
              </div>
              <div className="flex flex-col items-center text-center p-4 bg-gray-50 rounded-lg">
                <RefreshCw className="w-8 h-8 text-secondary mb-3" />
                <div>
                  <div className="font-medium mb-1">Política de Devolução</div>
                  <div className="text-sm text-gray-600">Processo simples de devolução em caso de insatisfação.</div>
                </div>
              </div>
              <div className="flex flex-col items-center text-center p-4 bg-gray-50 rounded-lg">
                <Headphones className="w-8 h-8 text-secondary mb-3" />
                <div>
                  <div className="font-medium mb-1">Suporte Dedicado</div>
                  <div className="text-sm text-gray-600">Equipa especializada para o ajudar em todas as etapas.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1. Other Sellers Section */}
      {otherSellers.length > 0 && (
        <div className="bg-white rounded-lg p-6 shadow-sm mb-8">
          <h2 className="text-xl font-semibold mb-4">Outros vendedores</h2>
          <div className="space-y-4">
            {otherSellers.map((seller) => (
              <div key={seller.id} className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      src={seller.logo || 'https://plataformaconcreta.pt/logos/lognow/default-company.png'}
                      alt={seller.name}
                      className="w-10 h-10 rounded-full mr-3"
                    />
                    <div>
                      <h3 className="font-medium text-gray-900">{seller.name}</h3>
                      <p className="text-xl font-bold text-gray-900">
                        €{getDisplayPrice(seller).toFixed(2)}
                      </p>
                      {isValidPromotion(seller) && (
                        <>
                          <p className="text-sm text-gray-500 line-through">
                            €{seller.regularPrice.toFixed(2)}
                          </p>
                          <div className="text-sm text-gray-600 mt-2">
                            <p>Promoção válida de {formatDate(seller.promotionalPeriod.startDate)} até {formatDate(seller.promotionalPeriod.endDate)}</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      handleAddToCart(seller);
                    }}
                    className="bg-[#C1D82F] hover:bg-[#a8bc29] text-white font-semibold py-2 px-6 rounded-lg"
                  >
                    Comprar
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* 2. Product Description Card */}
      <div className="bg-white rounded-lg p-6 shadow-sm mb-8">
        <h2 className="text-xl font-semibold mb-4">Descrição do Produto</h2>
        <div className="prose max-w-none">
          {product.description}
        </div>
      </div>

      {/* 3. Avaliações Card */}
      <div className="bg-white rounded-lg p-6 shadow-sm mb-8">
        <h2 className="text-xl font-semibold mb-6">Avaliações</h2>
        <div className="flex items-start">
          <div className="mr-8">
            <div className="text-4xl font-bold text-[#0047AB]">0.0<span className="text-lg">/5</span></div>
            <div className="flex items-center mt-1">
              {[1, 2, 3, 4, 5].map((star) => (
                <Star
                  key={star}
                  className="w-5 h-5 text-[#0047AB]"
                  style={{ opacity: 0.3 }}
                  fill="#0047AB"
                />
              ))}
            </div>
            <div className="text-sm text-[#0047AB] mt-1">
              0 avaliações
            </div>
          </div>

          {/* Star Breakdown */}
          <div className="flex-grow">
            {[5, 4, 3, 2, 1].map((stars) => (
              <div key={stars} className="flex items-center mb-2">
                <span className="w-20 text-sm text-gray-600">{stars} estrelas</span>
                <div className="flex-grow mx-4 h-2 bg-gray-200 rounded-full">
                  <div
                    className="h-2 bg-[#0047AB] rounded-full"
                    style={{ width: '0%' }}
                  ></div>
                </div>
                <span className="text-sm text-gray-600">0</span>
              </div>
            ))}
          </div>
        </div>

        {/* Search Reviews */}
        <div className="mt-6">
          <select className="mb-4 px-4 py-2 border rounded-lg text-gray-700 w-48">
            <option value="recent">Os mais recentes</option>
            <option value="highest">Melhor avaliação</option>
            <option value="lowest">Pior avaliação</option>
          </select>
          <div className="relative">
            <input
              type="text"
              placeholder="Procurar"
              className="w-full px-4 py-2 border rounded-lg text-gray-700"
            />
            <span className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </span>
          </div>
        </div>

        <div className="mt-4 text-gray-600">
          Ainda não existem avaliações para este produto.
        </div>
      </div>

      {/* Zoom Modal */}
      {showZoomedImage && (
        <div className="fixed inset-0 bg-black/80 z-50 flex items-center justify-center p-4" onClick={handleZoom}>
          <div className="relative max-w-4xl w-full">
            <img
              src={currentImage || product.mainImage}
              alt={product.name}
              className="w-full h-auto"
            />
            <button 
              className="absolute top-4 right-4 text-white hover:text-gray-300"
              onClick={handleZoom}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPage;