import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

const KuantoKustaFeed: React.FC = () => {
  const [xmlContent, setXmlContent] = useState<string>('');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, 'products');
        const snapshot = await getDocs(productsRef);
        
        let xmlContent = '<?xml version="1.0" encoding="UTF-8"?><products>';
        
        for (const doc of snapshot.docs) {
          const data = doc.data();
          
          // Pular produtos sem stock ou preço
          if (!data.stock || data.stock <= 0 || !data.price) continue;

          // Limpar e preparar os dados
          const description = (data.description || '').replace(/[\n\r]/g, ' ').replace(/\s+/g, ' ').trim();
          const title = (data.title || '').replace(/[\n\r]/g, ' ').replace(/\s+/g, ' ').trim();
          const category = (data.category || '').replace(/[\n\r]/g, ' ').replace(/\s+/g, ' ').trim();
          
          xmlContent += `<product>` +
            `<mode>comparison</mode>` +
            `<id_product>${doc.id}</id_product>` +
            `<product_url><![CDATA[https://emtuacasa.pt/product/${doc.id}]]></product_url>` +
            `<designation><![CDATA[${title}]]></designation>` +
            `<regular_price>${data.price.toFixed(2)}</regular_price>` +
            `<current_price>${data.price.toFixed(2)}</current_price>` +
            `<stock>${data.stock}</stock>` +
            `<category><![CDATA[${category}]]></category>` +
            `<image_url><![CDATA[${data.imageUrl || ''}]]></image_url>` +
            `<description><![CDATA[${description}]]></description>` +
            `<brand><![CDATA[${data.brand || 'OEM'}]]></brand>` +
            `<upc_ean><![CDATA[${data.ean || ''}]]></upc_ean>` +
            `<reference><![CDATA[${data.reference || ''}]]></reference>` +
            `<weight>${(data.weight || 0).toFixed(3)} kg</weight>` +
            `<shipping_cost>3.99</shipping_cost>` +
            `</product>`;
        }
        
        xmlContent += '</products>';

        setXmlContent(xmlContent);
      } catch (error) {
        console.error('Error generating feed:', error);
      }
    };

    fetchProducts();
  }, []);

  // Não renderizar nada visualmente, apenas retornar o XML
  useEffect(() => {
    if (xmlContent) {
      document.documentElement.innerHTML = xmlContent;
      document.querySelector('html')!.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
    }
  }, [xmlContent]);

  return null;
};

export default KuantoKustaFeed;
