import { useEffect, useState } from 'react';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Wallet } from '../types';
import { useAuth } from '../contexts/AuthContext';

export const useWallet = () => {
  const { user, userData, loading: authLoading } = useAuth();
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (authLoading) {
      return;
    }

    if (!user || !userData) {
      setLoading(false);
      setWallet(null);
      return;
    }

    const walletId = user.uid;
    setLoading(true);
    setError(null);

    const unsubscribe = onSnapshot(
      doc(db, 'wallets', walletId),
      async (docSnapshot) => {
        if (!docSnapshot.exists()) {
          const initialWallet: Wallet = {
            id: walletId,
            balance: 51.00,
            companyId: userData.companyId || '',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          };
          await setDoc(doc(db, 'wallets', walletId), initialWallet);
          return;
        }

        const data = docSnapshot.data();
        if (typeof data.balance !== 'number') {
          setError('Invalid wallet data format');
          setWallet(null);
        } else {
          const walletData: Wallet = {
            id: docSnapshot.id,
            balance: data.balance,
            companyId: data.companyId,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt
          };
          setWallet(walletData);
        }
        setLoading(false);
      },
      (err) => {
        setError('Failed to fetch wallet');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user, userData, authLoading]);

  return { wallet, loading, error };
};