import React from 'react';
import { useProducts } from '../hooks/useProducts';
import { Loader } from 'lucide-react';
import ProductCard from './ProductCard';

const PromotionalProducts = () => {
  const { products, loading, error } = useProducts(8, true); // true para mostrar apenas produtos em promoção

  if (loading) {
    return (
      <div className="flex justify-center items-center h-32">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 py-4">
        {error}
      </div>
    );
  }

  if (!products || products.length === 0) {
    return null;
  }

  return (
    <div className="my-12">
      <h2 className="text-2xl font-bold text-secondary mb-6">Produtos em Promoção</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {products.map((product) => (
          <ProductCard 
            key={product.id} 
            product={product}
            otherSellers={product.otherSellers}
          />
        ))}
      </div>
    </div>
  );
};

export default PromotionalProducts;
