import { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './DatePickerStyles.css';
import { setHours, setMinutes } from 'date-fns';

interface SellerFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SellerForm({ isOpen, onClose }: SellerFormProps) {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    sellsOnline: 'no',
    storeUrl: '',
    contactTime: null as Date | null
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Configurar horários disponíveis (10-12h e 14-16h, seg-sex)
  const filterTimes = (date: Date) => {
    const day = date.getDay();
    
    // Apenas dias úteis (1-5)
    return day !== 0 && day !== 6;
  };

  const getMorningTimes = () => {
    const times = [];
    const base = new Date();
    base.setSeconds(0);
    base.setMilliseconds(0);

    // 10:00 - 11:30
    for (let hour = 10; hour <= 11; hour++) {
      for (let minute = 0; minute <= 30; minute += 30) {
        const time = new Date(base);
        time.setHours(hour);
        time.setMinutes(minute);
        times.push(time);
      }
    }
    return times;
  };

  const getAfternoonTimes = () => {
    const times = [];
    const base = new Date();
    base.setSeconds(0);
    base.setMilliseconds(0);

    // 14:00 - 15:30
    for (let hour = 14; hour <= 15; hour++) {
      for (let minute = 0; minute <= 30; minute += 30) {
        const time = new Date(base);
        time.setHours(hour);
        time.setMinutes(minute);
        times.push(time);
      }
    }
    return times;
  };

  const availableTimes = [...getMorningTimes(), ...getAfternoonTimes()];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://hook.eu1.make.com/zqdx3w7o2znjc0bca64v3gtxk8jdl9su', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          sellsOnline: formData.sellsOnline,
          storeUrl: formData.storeUrl,
          contactTime: formData.contactTime?.toISOString(),
        }),
      });

      if (response.ok) {
        setSuccess(true);
        setTimeout(() => {
          onClose();
          setSuccess(false);
          setFormData({
            name: '',
            phone: '',
            email: '',
            sellsOnline: 'no',
            storeUrl: '',
            contactTime: null
          });
        }, 2000);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-[#0E2240] rounded-2xl p-8 max-w-md w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="text-2xl font-bold mb-6 text-white">Começar a vender</h2>

        {success ? (
          <div className="text-center py-8">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-[#C1D82F]/20 mb-4">
              <svg className="w-8 h-8 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <p className="text-white text-lg">Formulário enviado com sucesso!</p>
            <p className="text-gray-400">Entraremos em contato em breve.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">
                Nome
              </label>
              <input
                type="text"
                id="name"
                required
                className="w-full px-4 py-2 rounded-lg bg-[#1A2234] border border-[#C1D82F]/20 text-white focus:border-[#C1D82F] focus:ring-1 focus:ring-[#C1D82F] transition-colors"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-300 mb-1">
                Telefone
              </label>
              <input
                type="tel"
                id="phone"
                required
                className="w-full px-4 py-2 rounded-lg bg-[#1A2234] border border-[#C1D82F]/20 text-white focus:border-[#C1D82F] focus:ring-1 focus:ring-[#C1D82F] transition-colors"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                required
                className="w-full px-4 py-2 rounded-lg bg-[#1A2234] border border-[#C1D82F]/20 text-white focus:border-[#C1D82F] focus:ring-1 focus:ring-[#C1D82F] transition-colors"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Já vende online?
              </label>
              <div className="flex gap-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio text-[#C1D82F]"
                    name="sellsOnline"
                    value="yes"
                    checked={formData.sellsOnline === 'yes'}
                    onChange={(e) => setFormData({ ...formData, sellsOnline: e.target.value })}
                  />
                  <span className="ml-2 text-white">Sim</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio text-[#C1D82F]"
                    name="sellsOnline"
                    value="no"
                    checked={formData.sellsOnline === 'no'}
                    onChange={(e) => setFormData({ ...formData, sellsOnline: e.target.value })}
                  />
                  <span className="ml-2 text-white">Não</span>
                </label>
              </div>
            </div>

            {formData.sellsOnline === 'yes' && (
              <div>
                <label htmlFor="storeUrl" className="block text-sm font-medium text-gray-300 mb-1">
                  URL da sua loja
                </label>
                <input
                  type="url"
                  id="storeUrl"
                  className="w-full px-4 py-2 rounded-lg bg-[#1A2234] border border-[#C1D82F]/20 text-white focus:border-[#C1D82F] focus:ring-1 focus:ring-[#C1D82F] transition-colors"
                  value={formData.storeUrl}
                  onChange={(e) => setFormData({ ...formData, storeUrl: e.target.value })}
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Melhor horário para contacto
              </label>
              <DatePicker
                selected={formData.contactTime}
                onChange={(date) => setFormData({ ...formData, contactTime: date })}
                showTimeSelect
                filterDate={filterTimes}
                timeFormat="HH:mm"
                dateFormat="dd/MM/yyyy HH:mm"
                timeCaption="Hora"
                placeholderText="Selecione data e hora"
                calendarClassName="custom-datepicker"
                includeTimes={availableTimes}
                className="w-full px-4 py-2 rounded-lg bg-[#1A2234] border border-[#C1D82F]/20 text-white focus:border-[#C1D82F] focus:ring-1 focus:ring-[#C1D82F] transition-colors"
                required
                showTimeSelectOnly={false}
                timeIntervals={30}
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full px-6 py-3 rounded-lg bg-gradient-to-r from-[#C1D82F] to-[#d4ea3f] text-[#0A192F] font-semibold hover:shadow-lg hover:shadow-[#C1D82F]/20 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Enviando...' : 'Enviar'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
