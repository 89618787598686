import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useOrders } from '../hooks/useOrders';
import { useWallet } from '../hooks/useWallet';
import { usePendingReviews } from '../hooks/usePendingReviews';
import { useShippingAddresses } from '../hooks/useShippingAddresses';
import { Eye, Loader, MapPin, Wallet } from 'lucide-react';
import { OrderStatusMap, OrderStatusColorMap } from '../constants';
import ReviewForm from '../components/ReviewForm';
import AddressForm from '../components/AddressForm';
import AddressSelector from '../components/AddressSelector';

const ClientAreaPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { orders, loading: ordersLoading } = useOrders(user?.uid);
  const { wallet, loading: walletLoading } = useWallet();
  const { pendingReviews, loading: reviewsLoading } = usePendingReviews(user?.uid);
  const { addresses, loading: addressesLoading, deleteAddress } = useShippingAddresses(user?.uid);
  const [selectedReview, setSelectedReview] = useState<{
    productId: string;
    productName: string;
    orderId: string;
  } | null>(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [editingAddress, setEditingAddress] = useState<any | null>(null);

  // Calculate potential rewards
  const potentialReward = pendingReviews.length * 0.50;

  const getOrderStatusDisplay = (status: string) => {
    return OrderStatusMap[status] || status;
  };

  const getOrderStatusColor = (status: string) => {
    return OrderStatusColorMap[status] || 'bg-gray-100 text-gray-800';
  };

  const handleAddressEdit = (address: any) => {
    setEditingAddress(address);
    setShowAddressForm(true);
  };

  const handleAddressDelete = async (addressId: string) => {
    if (window.confirm('Tem certeza que deseja excluir este endereço?')) {
      await deleteAddress(addressId);
    }
  };

  if (ordersLoading || reviewsLoading || addressesLoading || walletLoading) {
    return (
      <div className="flex justify-center items-center h-96">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-secondary mb-8">Área de Cliente</h1>

      {/* Wallet Balance */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex items-center space-x-4">
          <Wallet className="h-8 w-8 text-primary" />
          <div>
            <h2 className="text-xl font-semibold text-secondary">Saldo Wallet</h2>
            <p className="text-2xl font-bold text-primary">€{wallet?.balance?.toFixed(2) || '0.00'}</p>
          </div>
        </div>
      </div>

      {/* Orders Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold text-secondary mb-6">Minhas Encomendas</h2>
        
        {orders.length === 0 ? (
          <p className="text-center text-gray-500">Nenhuma encomenda encontrada</p>
        ) : (
          <div className="space-y-4">
            {orders.map((order) => (
              <div
                key={order.id}
                className="border rounded-lg p-4 flex items-center justify-between hover:border-primary transition-colors"
              >
                <div>
                  <p className="font-medium text-secondary">#{order.orderNumber}</p>
                  <p className="text-sm text-gray-500">
                    {new Date(order.createdAt).toLocaleDateString('pt-PT')}
                  </p>
                  <p className="text-sm font-medium mt-1">
                    Total: €{order.total.toFixed(2)}
                  </p>
                </div>
                <div className="flex items-center space-x-4">
                  <span className={`px-3 py-1 rounded-full text-sm font-medium ${getOrderStatusColor(order.status)}`}>
                    {getOrderStatusDisplay(order.status)}
                  </span>
                  <button
                    onClick={() => navigate(`/order/${order.id}`)}
                    className="text-primary hover:text-primary-dark transition-colors"
                  >
                    <Eye className="w-5 h-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Pending Reviews Section */}
      {pendingReviews.length > 0 && (
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-secondary">Avaliações Pendentes</h2>
            <div className="bg-green-50 text-green-700 px-4 py-2 rounded-full font-medium animate-pulse">
              Está a perder €{potentialReward.toFixed(2)}! Avalie agora os produtos!
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {pendingReviews.map((review) => (
              <div key={`${review.orderId}-${review.product.id}`} className="border rounded-lg p-4">
                <div className="flex items-center space-x-4">
                  {review.product.mainImage && (
                    <img
                      src={review.product.mainImage}
                      alt={review.product.name}
                      className="w-16 h-16 object-cover rounded"
                    />
                  )}
                  <div>
                    <h3 className="font-medium text-secondary">{review.product.name}</h3>
                    <p className="text-sm text-gray-500">Encomenda: {review.orderNumber}</p>
                    <p className="text-sm text-green-600 mt-1">
                      Ganhe €0,50 ao avaliar!
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => setSelectedReview({
                    productId: review.product.id,
                    productName: review.product.name,
                    orderId: review.orderId
                  })}
                  className="mt-4 w-full bg-primary text-secondary py-2 px-4 rounded hover:bg-primary-dark transition-colors"
                >
                  Avaliar Produto
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Shipping Addresses Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-secondary flex items-center">
            <MapPin className="h-5 w-5 mr-2" />
            Moradas de Entrega
          </h2>
          <button
            onClick={() => {
              setEditingAddress(null);
              setShowAddressForm(true);
            }}
            className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors"
          >
            Adicionar Nova Morada
          </button>
        </div>

        {showAddressForm ? (
          <AddressForm
            address={editingAddress}
            onSubmit={() => {
              setShowAddressForm(false);
              setEditingAddress(null);
            }}
            onCancel={() => {
              setShowAddressForm(false);
              setEditingAddress(null);
            }}
          />
        ) : (
          <AddressSelector
            addresses={addresses}
            selectedAddressId={selectedAddress}
            onSelect={(address) => setSelectedAddress(address.id)}
            onAddNew={() => {
              setEditingAddress(null);
              setShowAddressForm(true);
            }}
            onEdit={handleAddressEdit}
            onDelete={handleAddressDelete}
          />
        )}
      </div>

      {/* Review Modal */}
      {selectedReview && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <ReviewForm
              productId={selectedReview.productId}
              productName={selectedReview.productName}
              orderId={selectedReview.orderId}
              onSuccess={() => setSelectedReview(null)}
            />
            <button
              onClick={() => setSelectedReview(null)}
              className="mt-4 w-full border border-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-50 transition-colors"
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientAreaPage;
