import React, { useState } from 'react';
import '../styles/animations.css';
import SellerForm from '../components/SellerForm';

const MaintenancePage = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!acceptedTerms) {
      setSubmitError('Por favor, aceita os termos de subscrição para continuar.');
      return;
    }

    if (!email) {
      setSubmitError('Por favor, insira um email válido.');
      return;
    }

    setIsSubmitting(true);
    setSubmitError('');

    try {
      const response = await fetch('https://hook.eu1.make.com/s5hwcixgdiyntovclkv2tnsn9jo91y3c', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          acceptedTerms,
          submittedAt: new Date().toISOString(),
        }),
      });

      if (!response.ok) {
        throw new Error('Falha ao enviar o formulário');
      }

      setEmail('');
      setAcceptedTerms(false);
      setShowSuccessModal(true);
    } catch (error) {
      setSubmitError('Ocorreu um erro ao processar a tua subscrição. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const TermsModal = () => (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-[#0E2240] rounded-2xl p-8 max-w-2xl w-full mx-4 relative">
        <button
          onClick={() => setShowTermsModal(false)}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="text-2xl font-bold mb-6 text-white">Termos da Subscrição</h2>
        <div className="space-y-4 text-gray-300">
          <p>
            A tua privacidade é algo que levamos muito a sério. Por isso, o e-mail que nos deu será usado apenas para te dar a conhecer mais sobre nós até ao lançamento do emtuacasa.pt.
          </p>
          <p>
            Se mudar de ideias pode sempre cancelar a tua subscrição através do e-mail unsubscribe@emtuacasa.pt. Ao submeter o teu e-mail está a concordar com o tratamento da tua informação nestes termos. Prometemos que não te vais arrepender!
          </p>
        </div>
      </div>
    </div>
  );

  const SuccessModal = () => (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-[#0E2240] rounded-2xl p-8 max-w-md w-full mx-4 text-center">
        <div className="mb-4">
          <div className="w-16 h-16 bg-[#C1D82F]/20 rounded-full flex items-center justify-center mx-auto">
            <svg className="w-8 h-8 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </div>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-white">Obrigado pela tua subscrição</h2>
        <button
          onClick={() => setShowSuccessModal(false)}
          className="px-6 py-2 bg-[#C1D82F] text-[#0A192F] rounded-lg font-semibold hover:bg-[#d4ea3f] transition-colors"
        >
          OK
        </button>
      </div>
    </div>
  );

  return (
    <div className="relative min-h-screen bg-[#0A192F] text-white overflow-hidden">
      {/* Background Decorations */}
      <div className="absolute top-0 -left-4 w-72 h-72 bg-[#C1D82F] rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob"></div>
      <div className="absolute top-0 -right-4 w-72 h-72 bg-[#C1D82F] rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-2000"></div>
      <div className="absolute -bottom-8 left-20 w-72 h-72 bg-[#C1D82F] rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-4000"></div>

      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 right-0 w-[800px] h-[800px] bg-gradient-to-b from-teal-400/20 to-transparent rounded-full blur-3xl transform -translate-y-1/2 translate-x-1/2 animate-pulse"></div>
        <div className="absolute -left-64 top-1/2 w-[400px] h-[400px] bg-[#C1D82F]/10 rounded-full blur-2xl animate-blob"></div>
        <div className="absolute -bottom-32 right-1/2 w-[300px] h-[300px] bg-purple-500/10 rounded-full blur-2xl animate-blob animation-delay-2000"></div>
      </div>

      {/* Animated Illustrations */}
      <div className="absolute right-10 top-20 animate-float-slow transform rotate-6">
        <img src="/images/app-preview.svg" alt="" className="w-72 h-auto filter drop-shadow-[0_0_15px_rgba(193,216,47,0.2)]" />
      </div>
      <div className="absolute left-20 top-40 animate-float animation-delay-2000">
        <img src="/images/floating-card.svg" alt="" className="w-64 h-auto filter drop-shadow-[0_0_10px_rgba(193,216,47,0.15)]" />
      </div>
      <div className="absolute right-1/4 bottom-40 animate-float animation-delay-4000 transform -rotate-3">
        <img src="/images/floating-card.svg" alt="" className="w-56 h-auto filter drop-shadow-[0_0_10px_rgba(193,216,47,0.15)]" />
      </div>

      {/* Floating Elements */}
      <div className="absolute right-20 top-20 animate-float-slow transform rotate-6">
        <div className="w-80 h-[480px] rounded-3xl bg-[#1A2234] border border-[#C1D82F]/10 p-6">
          <div className="flex items-center gap-2 text-white/80">
            <div className="w-4 h-4 rounded-sm bg-[#C1D82F]/30"></div>
            <span className="text-sm">App Preview</span>
          </div>
          <div className="mt-8 space-y-6">
            <div className="w-full h-28 rounded-2xl bg-[#2A3447]"></div>
            <div className="w-full h-28 rounded-2xl bg-[#2A3447]"></div>
            <div className="w-full h-28 rounded-2xl bg-[#2A3447]"></div>
          </div>
        </div>
      </div>
      <div className="absolute left-1/4 bottom-40 animate-float animation-delay-2000 transform -rotate-12">
        <div className="w-64 h-72 rounded-xl bg-[#1A2234] border border-[#C1D82F]/10 p-4">
          <div className="flex items-center gap-2 text-white/80">
            <div className="w-4 h-4 rounded-sm bg-[#C1D82F]/30"></div>
            <span className="text-sm">Product</span>
          </div>
          <div className="mt-auto space-y-3">
            <div className="w-full h-2 rounded-full bg-[#2A3447]"></div>
            <div className="w-3/4 h-2 rounded-full bg-[#2A3447]"></div>
          </div>
        </div>
      </div>
      <div className="absolute right-1/3 top-1/3 animate-float animation-delay-4000 transform rotate-[-6deg]">
        <div className="w-56 h-64 rounded-xl bg-[#1A2234] border border-[#C1D82F]/10 p-4">
          <div className="flex items-center gap-2 text-white/80">
            <div className="w-4 h-4 rounded-sm bg-[#C1D82F]/30"></div>
            <span className="text-sm">Product</span>
          </div>
          <div className="mt-auto space-y-3">
            <div className="w-full h-2 rounded-full bg-[#2A3447]"></div>
            <div className="w-3/4 h-2 rounded-full bg-[#2A3447]"></div>
          </div>
        </div>
      </div>

      {/* Floating devices */}
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/4 w-[600px] h-[600px] pointer-events-none">
        <div className="relative w-full h-full">
          {/* Phone mockup */}
          <div className="absolute top-0 right-0 w-[300px] animate-float-slow">
            <div className="bg-white rounded-[40px] p-3 shadow-2xl transform rotate-12">
              <div className="bg-[#0A192F] rounded-[32px] overflow-hidden">
                <div className="w-full h-full bg-[#1A2234] rounded-[32px] p-4">
                  <div className="flex items-center gap-2 text-white/80">
                    <div className="w-4 h-4 rounded-sm bg-[#C1D82F]/30"></div>
                    <span className="text-sm">App Preview</span>
                  </div>
                  <div className="mt-8 space-y-6">
                    <div className="w-full h-28 rounded-2xl bg-[#2A3447]"></div>
                    <div className="w-full h-28 rounded-2xl bg-[#2A3447]"></div>
                    <div className="w-full h-28 rounded-2xl bg-[#2A3447]"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Floating product cards */}
          <div className="absolute top-1/4 left-0 w-[200px] animate-float animation-delay-1000">
            <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-4 shadow-xl transform -rotate-6">
              <div className="aspect-square rounded-xl overflow-hidden mb-3">
                <div className="w-full h-full bg-[#2A3447] rounded-xl"></div>
              </div>
              <div className="space-y-1">
                <div className="h-4 bg-white/20 rounded"></div>
                <div className="h-4 bg-white/20 rounded w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute right-10 top-20 animate-float-slow">
        <div className="w-64 h-96 rounded-3xl border-2 border-[#C1D82F]/30 bg-gradient-to-br from-[#C1D82F]/10 to-transparent backdrop-blur-sm transform rotate-12">
          <div className="w-full h-4 rounded-t-3xl bg-[#C1D82F]/20 backdrop-blur-sm"></div>
        </div>
      </div>
      <div className="absolute left-10 bottom-20 animate-float">
        <div className="w-48 h-48 rounded-2xl border-2 border-[#C1D82F]/30 bg-gradient-to-br from-[#C1D82F]/10 to-transparent backdrop-blur-sm">
          <div className="w-8 h-8 rounded-full bg-[#C1D82F]/20 m-4"></div>
          <div className="w-32 h-2 bg-[#C1D82F]/20 mx-4 rounded-full"></div>
          <div className="w-24 h-2 bg-[#C1D82F]/20 mx-4 mt-2 rounded-full"></div>
        </div>
      </div>

      {/* Main content */}
      <div className="relative z-10">
        <header className="container mx-auto px-4 pt-8 pb-4">
          <img
            src="https://plataformaconcreta.pt/logos/emtuacasa/logo_branco_emtuacasa.png"
            alt="EmTuaCasa Logo"
            className="h-16 md:h-20 mx-auto"
          />
        </header>

        <main className="container mx-auto px-4 pt-12 pb-20">
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-16">
              <h1 className="text-4xl md:text-7xl font-bold mb-8 leading-tight">
                O maior shopping online
                <span className="block text-[#C1D82F] mt-2">de Portugal está a chegar.</span>
              </h1>
              
              <p className="text-xl md:text-2xl text-gray-300 mb-12 max-w-2xl mx-auto">
                Uma nova era de compras online com mais variedade, 
                conveniência e uma experiência verdadeiramente única.
              </p>

              {/* Newsletter form */}
              <div className="max-w-xl mx-auto">
                <div className="bg-[#0E2240]/80 backdrop-blur-xl p-8 rounded-2xl border border-[#C1D82F]/10">
                  <h2 className="text-[#C1D82F] text-xl font-semibold mb-6 text-center">
                    Regista-te na nossa newsletter e sê o primeiro a conhecer
                  </h2>
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="relative">
                      <input
                        type="email"
                        placeholder="O teu email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-3 pr-[120px] rounded-lg bg-[#1A2234] border border-[#C1D82F]/20 text-white focus:border-[#C1D82F] focus:ring-1 focus:ring-[#C1D82F] transition-colors placeholder-gray-500"
                      />
                      <button 
                        type="submit"
                        disabled={isSubmitting || !acceptedTerms}
                        className={`absolute right-2 top-1/2 -translate-y-1/2 px-6 py-2 rounded-md bg-gradient-to-r from-[#C1D82F] to-[#d4ea3f] text-[#0A192F] font-semibold transition-all duration-300 ${
                          (!acceptedTerms || isSubmitting) 
                            ? 'opacity-50 cursor-not-allowed' 
                            : 'hover:shadow-lg hover:shadow-[#C1D82F]/20'
                        }`}
                      >
                        {isSubmitting ? 'A enviar...' : 'Subscrever'}
                      </button>
                    </div>
                    
                    <div className="flex items-start gap-3">
                      <div className="flex-shrink-0 mt-1">
                        <input
                          type="checkbox"
                          id="terms"
                          checked={acceptedTerms}
                          onChange={(e) => {
                            setAcceptedTerms(e.target.checked);
                            if (e.target.checked) {
                              setSubmitError('');
                            }
                          }}
                          className="h-4 w-4 rounded border-gray-600 text-[#C1D82F] focus:ring-[#C1D82F] focus:ring-offset-[#0E2240]"
                        />
                      </div>
                      <label htmlFor="terms" className="text-sm text-gray-400 leading-relaxed">
                        Li e aceito os{" "}
                        <button
                          type="button"
                          onClick={() => setShowTermsModal(true)}
                          className="text-[#C1D82F] hover:text-[#d4ea3f] underline font-medium"
                        >
                          termos de subscrição
                        </button>
                      </label>
                    </div>

                    {submitError && (
                      <div className="text-red-400 text-sm text-center">
                        {submitError}
                      </div>
                    )}

                    <div className="flex items-center justify-center gap-2 text-sm text-gray-400 pt-2 border-t border-[#C1D82F]/10">
                      <svg className="w-4 h-4 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                      </svg>
                      Os teus dados estão seguros connosco
                    </div>
                  </form>
                </div>
              </div>

              {/* Feature Cards */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-7xl mx-auto px-4 mt-20">
                <div className="group">
                  <div className="relative bg-gradient-to-b from-[#0E2240]/95 to-[#0A192F]/95 backdrop-blur-xl p-8 rounded-2xl border border-[#C1D82F]/10 hover:border-[#C1D82F]/30 transition-all duration-500 transform hover:-translate-y-1 hover:shadow-[0_0_25px_rgba(193,216,47,0.1)]">
                    <div className="absolute -top-3 -right-3 w-24 h-24 bg-[#C1D82F] rounded-full blur-[64px] opacity-20 group-hover:opacity-30 transition-opacity"></div>
                    <div className="relative">
                      <div className="inline-block p-3.5 bg-gradient-to-br from-[#C1D82F]/20 to-[#C1D82F]/5 rounded-2xl mb-6 group-hover:from-[#C1D82F]/30 group-hover:to-[#C1D82F]/10 transition-all duration-300">
                        <svg className="w-7 h-7 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H5a1 1 0 01-1-1v-12a1 1 0 011-1z" />
                        </svg>
                      </div>
                      <h3 className="text-xl font-semibold mb-4 bg-gradient-to-r from-[#C1D82F] to-[#d4ea3f] bg-clip-text text-transparent">
                        Variedade Incomparável
                      </h3>
                      <p className="text-gray-300/90 leading-relaxed text-[15px] font-light">
                        Mais de 1M de produtos em 17 categorias. Das marcas que adoras, das mais pequenas às mais conhecidas.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="group">
                  <div className="relative bg-gradient-to-b from-[#0E2240]/95 to-[#0A192F]/95 backdrop-blur-xl p-8 rounded-2xl border border-[#C1D82F]/10 hover:border-[#C1D82F]/30 transition-all duration-500 transform hover:-translate-y-1 hover:shadow-[0_0_25px_rgba(193,216,47,0.1)]">
                    <div className="absolute -top-3 -right-3 w-24 h-24 bg-[#C1D82F] rounded-full blur-[64px] opacity-20 group-hover:opacity-30 transition-opacity"></div>
                    <div className="relative">
                      <div className="inline-block p-3.5 bg-gradient-to-br from-[#C1D82F]/20 to-[#C1D82F]/5 rounded-2xl mb-6 group-hover:from-[#C1D82F]/30 group-hover:to-[#C1D82F]/10 transition-all duration-300">
                        <svg className="w-7 h-7 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </div>
                      <h3 className="text-xl font-semibold mb-4 bg-gradient-to-r from-[#C1D82F] to-[#d4ea3f] bg-clip-text text-transparent">
                        Conveniência Total
                      </h3>
                      <p className="text-gray-300/90 leading-relaxed text-[15px] font-light">
                        Mais de 1500 pontos de recolha. Recebe onde quiseres, à hora que escolheres, com pagamentos 100% seguros.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="group">
                  <div className="relative bg-gradient-to-b from-[#0E2240]/95 to-[#0A192F]/95 backdrop-blur-xl p-8 rounded-2xl border border-[#C1D82F]/10 hover:border-[#C1D82F]/30 transition-all duration-500 transform hover:-translate-y-1 hover:shadow-[0_0_25px_rgba(193,216,47,0.1)]">
                    <div className="absolute -top-3 -right-3 w-24 h-24 bg-[#C1D82F] rounded-full blur-[64px] opacity-20 group-hover:opacity-30 transition-opacity"></div>
                    <div className="relative">
                      <div className="inline-block p-3.5 bg-gradient-to-br from-[#C1D82F]/20 to-[#C1D82F]/5 rounded-2xl mb-6 group-hover:from-[#C1D82F]/30 group-hover:to-[#C1D82F]/10 transition-all duration-300">
                        <svg className="w-7 h-7 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                      </div>
                      <h3 className="text-xl font-semibold mb-4 bg-gradient-to-r from-[#C1D82F] to-[#d4ea3f] bg-clip-text text-transparent">
                        Experiência Única
                      </h3>
                      <p className="text-gray-300/90 leading-relaxed text-[15px] font-light">
                        Dicas personalizadas, tendências e sugestões dos nossos especialistas para uma experiência de compra superior.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* Vantagens para Vendedores */}
        <div className="relative mt-32 mb-20 overflow-hidden">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-[#C1D82F] to-[#d4ea3f] bg-clip-text text-transparent">
                Vender no EMTUACASA
              </h2>
              <p className="text-gray-300/90 text-lg">
                Começa a vender hoje e alcança milhões de clientes em todo Portugal
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8 relative">
              {/* Decorative Elements */}
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[600px] h-[600px] bg-[#C1D82F] rounded-full blur-[120px] opacity-[0.07]"></div>

              {/* Card 1 */}
              <div className="relative group">
                <div className="bg-gradient-to-b from-[#0E2240]/95 to-[#0A192F]/95 backdrop-blur-xl p-8 rounded-2xl border border-[#C1D82F]/10 hover:border-[#C1D82F]/30 transition-all duration-500 h-full">
                  <div className="mb-6 inline-flex p-3 rounded-2xl bg-gradient-to-br from-[#C1D82F]/20 to-[#C1D82F]/5">
                    <svg className="w-8 h-8 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-white">Promove a tua marca</h3>
                  <p className="text-gray-300/90 leading-relaxed text-[15px] font-light">
                    Cria a tua loja online com imagens profissionais, vídeos envolventes e avaliações detalhadas dos teus produtos.
                  </p>
                </div>
              </div>

              {/* Card 2 */}
              <div className="relative group">
                <div className="bg-gradient-to-b from-[#0E2240]/95 to-[#0A192F]/95 backdrop-blur-xl p-8 rounded-2xl border border-[#C1D82F]/10 hover:border-[#C1D82F]/30 transition-all duration-500 h-full">
                  <div className="mb-6 inline-flex p-3 rounded-2xl bg-gradient-to-br from-[#C1D82F]/20 to-[#C1D82F]/5">
                    <svg className="w-8 h-8 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 12a3 3 0 11-6 0 3 3 0 016 0zm6 0a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-white">Aumenta a tua visibilidade</h3>
                  <p className="text-gray-300/90 leading-relaxed text-[15px] font-light">
                    Milhares de clientes terão acesso aos teus produtos, marca e loja. Alcança um público maior e mais diversificado.
                  </p>
                </div>
              </div>

              {/* Card 3 */}
              <div className="relative group">
                <div className="bg-gradient-to-b from-[#0E2240]/95 to-[#0A192F]/95 backdrop-blur-xl p-8 rounded-2xl border border-[#C1D82F]/10 hover:border-[#C1D82F]/30 transition-all duration-500 h-full">
                  <div className="mb-6 inline-flex p-3 rounded-2xl bg-gradient-to-br from-[#C1D82F]/20 to-[#C1D82F]/5">
                    <svg className="w-8 h-8 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-white">Atrai novos clientes</h3>
                  <p className="text-gray-300/90 leading-relaxed text-[15px] font-light">
                    Cria campanhas personalizadas e ofertas especiais para captar novos clientes e fidelizar os existentes.
                  </p>
                </div>
              </div>
            </div>

            <div className="text-center mt-12">
              <button 
                onClick={() => setIsFormOpen(true)}
                className="inline-flex items-center px-8 py-3 rounded-full bg-gradient-to-r from-[#C1D82F] to-[#d4ea3f] text-[#0A192F] font-semibold hover:shadow-lg hover:shadow-[#C1D82F]/20 transition-all duration-300 transform hover:-translate-y-0.5"
              >
                Começar a vender
                <svg className="w-5 h-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Recursos Exclusivos */}
        <div className="relative py-20 bg-gradient-to-b from-[#0A192F] to-[#0E2240]">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-[#C1D82F] to-[#d4ea3f] bg-clip-text text-transparent">
                Tudo o que precisas para vender online
              </h2>
              <p className="text-gray-300/90 text-lg">
                Ferramentas completas e integradas para impulsionar o teu negócio
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-6">
              {[
                {
                  title: "Marketplace B2B",
                  description: "Acede à área exclusiva de vendas entre vendedores do EMTUACASA e expande o teu negócio B2B.",
                  icon: "M17 20h5v-2a3 3 0 110-6 3 3 0 010 6zm0 0h-5V8a3 3 0 013-3v9a3 3 0 010 6zm-9-9V6a3 3 0 011-3h5a3 3 0 013 3v9a3 3 0 01-6 0z"
                },
                {
                  title: "Rede Social",
                  description: "Conecta-te com outros vendedores, partilha experiências e cria parcerias através da nossa rede social.",
                  icon: "M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                },
                {
                  title: "Envios 24/48h",
                  description: "As recolhas e entregas são asseguradas pela SHIPPIX, garantindo rapidez e eficiência nas tuas encomendas.",
                  icon: "M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h8a1 1 0 001-1zm8-6v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6a1 1 0 011-1h2a1 1 0 011 1z"
                },
                {
                  title: "Wallet Integrada",
                  description: "O valor das tuas vendas fica na wallet. Usa para compras B2B ou transfere diretamente para a tua conta bancária.",
                  icon: "M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                },
                {
                  title: "Sincronização Total",
                  description: "Integra e sincroniza a tua loja online existente com a nossa plataforma automaticamente.",
                  icon: "M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                },
                {
                  title: "Gestão Centralizada",
                  description: "Faz a gestão de todas as tuas encomendas do EMTUACASA e da tua loja online num único local.",
                  icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 11-4 0 2 2 0 014 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm0 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                },
                {
                  title: "Fulfillment Integrado",
                  description: "Integração com Amazon, Fnac, Worten e KuantoKusta para maximizar as tuas vendas.",
                  icon: "M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                },
                {
                  title: "Comissão Competitiva",
                  description: "Apenas 15%+IVA por venda, sem custos ocultos.",
                  icon: "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                },
                {
                  title: "Envios Gratuitos",
                  description: "Custos de envio suportados pelos clientes, sem despesas para ti.",
                  icon: "M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                },
                {
                  title: "Suporte Dedicado",
                  description: "Equipa especializada para te ajudar em todas as etapas do teu negócio.",
                  icon: "M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                },
                {
                  title: "Faturação Integrada",
                  description: "Sistema de faturação simplificado e totalmente integrado na plataforma.",
                  icon: "M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                },
                {
                  title: "Página Personalizada",
                  description: "Página dedicada ao teu negócio com URL personalizado para fortalecer a tua marca.",
                  icon: "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                }
              ].map((feature, index) => (
                <div key={index} className="group">
                  <div className="bg-gradient-to-br from-[#0E2240]/80 to-[#0A192F]/80 backdrop-blur-xl p-6 rounded-2xl border border-[#C1D82F]/10 hover:border-[#C1D82F]/30 transition-all duration-500 h-full">
                    <div className="flex items-start gap-4">
                      <div className="inline-flex p-2.5 rounded-xl bg-gradient-to-br from-[#C1D82F]/20 to-[#C1D82F]/5 group-hover:from-[#C1D82F]/30 group-hover:to-[#C1D82F]/10 transition-all duration-300">
                        <svg className="w-6 h-6 text-[#C1D82F]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d={feature.icon} />
                        </svg>
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-2 text-white group-hover:text-[#C1D82F] transition-colors">
                          {feature.title}
                        </h3>
                        <p className="text-gray-300/90 leading-relaxed text-sm font-light">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-center mt-12">
              <button 
                onClick={() => setIsFormOpen(true)}
                className="inline-flex items-center px-8 py-3 rounded-full bg-gradient-to-r from-[#C1D82F] to-[#d4ea3f] text-[#0A192F] font-semibold hover:shadow-lg hover:shadow-[#C1D82F]/20 transition-all duration-300 transform hover:-translate-y-0.5"
              >
                Começar a vender
                <svg className="w-5 h-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {showTermsModal && <TermsModal />}
      {showSuccessModal && <SuccessModal />}
      <SellerForm isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} />
    </div>
  );
};

export default MaintenancePage;
