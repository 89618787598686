import React, { useEffect, useState } from 'react';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

const DemoMessage = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Reference to the specific document
    const docRef = doc(db, 'Definicoes do site', 'marketplace');

    // Set up real-time listener
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setShowMessage(data.header?.showMessage || false);
        setMessage(data.header?.message || '');
      }
    }, (error) => {
      console.error('Error listening to demo message config:', error);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (!showMessage || !message) return null;

  return (
    <div className="bg-[#c2e205] shadow-sm">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-center py-2 px-4 text-[#1B3B68] animate-subtle-fade">
          <svg 
            className="w-5 h-5 mr-3"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span className="text-sm font-medium tracking-wide">
            {message}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DemoMessage;
