import React from 'react';
import { Link } from 'react-router-dom';
import { Package2, LayoutGrid, Store } from 'lucide-react';

const SubNavbar = () => {
  const navItems = [
    {
      icon: <Package2 className="w-5 h-5" />,
      text: 'Seguir encomenda',
      href: '/track-order'
    },
    {
      icon: <LayoutGrid className="w-5 h-5" />,
      text: 'Categorias',
      href: '/categories'
    },
    {
      icon: <Store className="w-5 h-5" />,
      text: 'Vantagens em ser vendedor',
      href: '/vendedor'
    }
  ];

  return (
    <div className="bg-gray-100 border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-8 py-2">
          {navItems.map((item) => (
            <Link
              key={item.href}
              to={item.href}
              className="flex items-center space-x-2 text-sm text-gray-600 hover:text-primary transition-colors py-1"
            >
              {item.icon}
              <span className="whitespace-nowrap">{item.text}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubNavbar;
