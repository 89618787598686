import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface MaintenanceCheckProps {
  children: React.ReactNode;
}

const MaintenanceCheck: React.FC<MaintenanceCheckProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Configurar listener em tempo real para o status de manutenção
    const maintenanceRef = doc(db, 'Definicoes do site', 'marketplace');
    
    const unsubscribe = onSnapshot(maintenanceRef, (snapshot) => {
      const data = snapshot.data();
      const maintenanceEnabled = data?.maintenance?.enabled;
      console.log('Maintenance status:', maintenanceEnabled);
      
      const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
      const isMaintenancePage = location.pathname === '/maintenance';
      
      // Se estiver em localhost, só redireciona se acessar diretamente a página de manutenção
      if (isLocalhost) {
        if (maintenanceEnabled === false && isMaintenancePage) {
          console.log('Localhost: Redirecting from maintenance to home...');
          navigate('/', { replace: true });
        }
        return; // Ignora outras verificações em localhost
      }

      // Para outros ambientes, aplica a lógica normal
      if (maintenanceEnabled === true && !isMaintenancePage) {
        console.log('Redirecting to maintenance page...');
        navigate('/maintenance', { replace: true });
      } else if (maintenanceEnabled === false && isMaintenancePage) {
        console.log('Redirecting to home page...');
        navigate('/', { replace: true });
      }
    }, (error) => {
      console.error('Error checking maintenance status:', error);
    });

    return () => unsubscribe();
  }, [navigate, location]);

  return <>{children}</>;
};

export default MaintenanceCheck;
