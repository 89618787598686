import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Loader } from 'lucide-react';
import ProductCard from '../components/ProductCard';

interface Product {
  id: string;
  name: string;
  price: number;
  description: string;
  images: string[];
  category: string;
  ean?: string;
}

const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q') || '';
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const searchTermLower = searchQuery.toLowerCase();
        
        // Buscar todos os produtos e filtrar no cliente
        const productsRef = collection(db, 'products');
        const q = query(productsRef);
        
        const querySnapshot = await getDocs(q);
        const productsData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(product => {
            const nameMatch = product.name?.toLowerCase().includes(searchTermLower);
            const categoryMatch = product.category?.toLowerCase().includes(searchTermLower);
            const eanMatch = product.ean === searchQuery;
            return nameMatch || categoryMatch || eanMatch;
          }) as Product[];
        
        setProducts(productsData);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery) {
      fetchProducts();
    } else {
      setProducts([]);
      setLoading(false);
    }
  }, [searchQuery]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-secondary mb-8">
        Resultados para "{searchQuery}"
      </h1>

      {products.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-xl text-gray-600">
            Nenhum produto encontrado para sua pesquisa.
          </p>
          <p className="text-gray-500 mt-2">
            Tente pesquisar com termos diferentes ou navegue por nossas categorias.
          </p>
        </div>
      ) : (
        <>
          <p className="text-gray-600 mb-6">
            {products.length} {products.length === 1 ? 'produto encontrado' : 'produtos encontrados'}
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SearchPage;
