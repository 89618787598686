import React from 'react';
import RegisterForm from '../components/auth/RegisterForm';
import { useAuth } from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';

const RegisterPage = () => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/client-area" replace />;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="https://plataformaconcreta.pt/logos/emtuacasa/logo_pretoemtuacasa.png"
          alt="EM TUA CASA®"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-secondary">
          Criar nova conta
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Ou{' '}
          <a href="/login" className="font-medium text-primary hover:text-primary-dark">
            faça login na sua conta existente
          </a>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <RegisterForm />
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;