import { useEffect, useState } from 'react';
import { collection, query, getDocs, where, orderBy, limit, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product } from '../types';

interface ProductWithSellers extends Product {
  otherSellers: number;
  sellerName: string;
}

export const useRecentProducts = (limitCount = 8) => {
  const [products, setProducts] = useState<ProductWithSellers[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRecentProducts = async () => {
      try {
        setLoading(true);
        const productsRef = collection(db, 'products');
        const q = query(
          productsRef,
          where('stockStatus', '==', 'instock'),
          where('isPrivateSale', '==', true),
          orderBy('createdAt', 'desc'),
          limit(limitCount * 2)
        );

        const snapshot = await getDocs(q);
        const allProducts = snapshot.docs
          .map(doc => {
            const data = doc.data();
            if (!data) return null;
            return {
              id: doc.id,
              ...data
            };
          })
          .filter((product): product is Product => 
            product !== null && 
            typeof product === 'object' &&
            'id' in product
          );

        // Agrupar produtos por EAN
        const productsByEan = allProducts.reduce((acc, product) => {
          if (!product?.ean) return acc;
          if (!acc[product.ean]) {
            acc[product.ean] = [];
          }
          acc[product.ean].push(product);
          return acc;
        }, {} as { [ean: string]: Product[] });

        // Filtrar e processar produtos
        const currentDate = new Date();
        const processedProducts = Object.values(productsByEan).map(async products => {
          // Filtrar produtos válidos
          const validProducts = products.filter(p => {
            if (!p || typeof p !== 'object') return false;
            
            const hasStock = typeof p.stock === 'number' && p.stock > 0;
            const regularPrice = typeof p.regularPrice === 'number' ? p.regularPrice : Number(p.regularPrice);
            const hasValidPrice = !isNaN(regularPrice) && regularPrice > 0;
            
            // Verificar se o produto está em promoção
            const isOnPromotion = p.promotionalPeriod &&
              p.promotionalPeriod.startDate &&
              p.promotionalPeriod.endDate &&
              new Date(p.promotionalPeriod.startDate) <= currentDate &&
              new Date(p.promotionalPeriod.endDate) >= currentDate &&
              typeof p.salePrice === 'number' &&
              p.salePrice < regularPrice;

            // Excluir produtos em promoção
            return hasStock && hasValidPrice && !isOnPromotion;
          });

          if (validProducts.length === 0) return null;

          // Encontrar o melhor vendedor (menor preço)
          const mainSeller = validProducts.reduce((best, current) => {
            return current.regularPrice < best.regularPrice ? current : best;
          });

          // Buscar dados da empresa do vendedor
          const companyRef = doc(db, 'companies', mainSeller.companyId);
          const companyDoc = await getDoc(companyRef);
          const companyData = companyDoc.exists() ? companyDoc.data() : null;

          // Adicionar contagem de outros vendedores e nome da empresa
          return {
            ...mainSeller,
            otherSellers: validProducts.length - 1,
            sellerName: companyData?.commercialName || companyData?.name || mainSeller.companyName
          };
        }).filter((p): p is Promise<ProductWithSellers> => p !== null);

        // Esperar todas as promessas serem resolvidas
        const resolvedProducts = await Promise.all(processedProducts);

        // Ordenar por data de criação
        const sortedProducts = resolvedProducts
          .filter(product => product !== null && product !== undefined)
          .sort((a, b) => {
            const aDate = a?.createdAt ? new Date(a.createdAt) : new Date(0);
            const bDate = b?.createdAt ? new Date(b.createdAt) : new Date(0);
            return bDate.getTime() - aDate.getTime();
          });

        setProducts(sortedProducts.slice(0, limitCount));
        setError(null);
      } catch (err) {
        console.error('Error fetching recent products:', err);
        setError('Erro ao carregar produtos recentes');
        setProducts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentProducts();
  }, [limitCount]);

  return { products, loading, error };
};