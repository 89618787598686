import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useOrders } from '../hooks/useOrders';
import { useWallet } from '../hooks/useWallet';
import { usePendingReviews } from '../hooks/usePendingReviews';
import { useShippingAddresses } from '../hooks/useShippingAddresses';
import { Eye, Loader, MapPin } from 'lucide-react';
import { auth } from '../lib/firebase';
import { OrderStatusMap, OrderStatusColorMap } from '../constants';
import ReviewForm from '../components/ReviewForm';
import AddressForm from '../components/AddressForm';
import AddressSelector from '../components/AddressSelector';
import ClientAreaPage from './ClientAreaPage';

const ClientArea = () => {
  const navigate = useNavigate();
  const { user, userRole, loading: authLoading } = useAuth();
  const { orders, loading: ordersLoading } = useOrders(user?.uid);
  const { wallet } = useWallet(user?.uid);
  const { pendingReviews, loading: reviewsLoading } = usePendingReviews(user?.uid);
  const { addresses, loading: addressesLoading, deleteAddress } = useShippingAddresses(user?.uid);
  const [selectedReview, setSelectedReview] = useState<{
    productId: string;
    productName: string;
    orderId: string;
  } | null>(null);
  const [showAddressForm, setShowAddressForm] = useState(false);

  useEffect(() => {
    if (!authLoading && !user) {
      navigate('/login?redirect=/client-area');
    }

    if (user && (userRole === 'gerente' || userRole === 'funcionario')) {
      auth.signOut();
      navigate('/login');
    }
  }, [user, userRole, navigate, authLoading]);

  if (authLoading || ordersLoading || reviewsLoading || addressesLoading) {
    return (
      <div className="flex justify-center items-center h-96">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login?redirect=/client-area" replace />;
  }

  return (
    <Routes>
      <Route index element={<ClientAreaPage />} />
      <Route path="*" element={<Navigate to="/client-area" replace />} />
    </Routes>
  );
};

export default ClientArea;