import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { slugify } from '../utils/slugify';
import { getIconFromName } from '../utils/categoryIcons';

interface Category {
  id: string;
  name: string;
  productCount?: number;
  icon?: React.ElementType;
}

interface CategoriesMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const CategoriesMenu: React.FC<CategoriesMenuProps> = ({ isOpen, onClose }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const categoriesRef = collection(db, 'categories');
        const productsRef = collection(db, 'products');
        
        // Primeiro, buscar todas as categorias
        const categoriesSnapshot = await getDocs(categoriesRef);
        const allCategories = categoriesSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            icon: getIconFromName(data.name || '')
          };
        });

        // Depois, buscar produtos que estão em private sale
        const productsSnapshot = await getDocs(
          query(productsRef, where('isPrivateSale', '==', true))
        );
        
        // Contar produtos por categoria
        const categoryCount = new Map<string, number>();
        productsSnapshot.docs.forEach(doc => {
          const product = doc.data();
          if (product.category) {
            const count = categoryCount.get(product.category) || 0;
            categoryCount.set(product.category, count + 1);
          }
        });

        // Adicionar contagem aos objetos de categoria
        const categoriesWithCount = allCategories.map(category => ({
          ...category,
          productCount: categoryCount.get(category.name) || 0
        }));

        // Filtrar categorias com produtos e ordenar por nome
        const validCategories = categoriesWithCount
          .filter(category => category.productCount > 0)
          .sort((a, b) => a.name.localeCompare(b.name));

        setCategories(validCategories);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
      <div className="fixed inset-y-0 left-0 max-w-xs w-full bg-[#1B3B68] shadow-xl">
        <div className="h-full flex flex-col">
          <div className="px-4 py-3 border-b border-[#2c4f82] flex items-center justify-between bg-[#0A2647]">
            <h2 className="text-lg font-medium text-white">Todos os departamentos</h2>
            <button
              onClick={onClose}
              className="p-2 rounded-full text-white hover:bg-[#2c4f82] transition-colors"
              aria-label="Fechar menu"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          
          <div className="flex-1 overflow-y-auto bg-white">
            {loading ? (
              <div className="flex justify-center items-center h-32">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#1B3B68]"></div>
              </div>
            ) : categories.length > 0 ? (
              <nav className="py-2">
                {categories.map((category) => (
                  <Link
                    key={category.id}
                    to={`/category/${slugify(category.name)}`}
                    className="flex items-center justify-between px-4 py-2.5 hover:bg-gray-50 border-b border-gray-100 text-[#1B3B68]"
                    onClick={onClose}
                  >
                    <div className="flex items-center space-x-3">
                      {category.icon && (
                        <category.icon className="w-5 h-5 text-gray-500" />
                      )}
                      <span className="font-medium">{category.name}</span>
                    </div>
                    {category.productCount && (
                      <span className="text-sm text-gray-500 bg-gray-100 px-2 py-0.5 rounded-full">
                        {category.productCount}
                      </span>
                    )}
                  </Link>
                ))}
              </nav>
            ) : (
              <div className="flex justify-center items-center h-32 text-gray-500">
                Nenhuma categoria encontrada
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesMenu;
